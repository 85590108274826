import { useAuthStore } from '~/stores/auth'
import { useSnackbarStore } from '~/stores/snackbar'
import type { User as T, ErrorT } from '~/types'

export const useUserStore = defineStore('userStore', () => {
   const config = useRuntimeConfig()
   const headers = computed(() => { return useAuthStore().headers })
   const authStore = useAuthStore()
   const { setSnackbar } = useSnackbarStore()
   const { showError } = useAPI()

   const current = ref<T>({} as T)
   const users = ref<T[]>([])
   const total = ref(0)
   const user = ref<T>({} as T)
   const tempUser = ref<T>({} as T)
   const hiddenUsers = ref<T[]>([])
   const hiddenTotal = ref(0)
   const loginUsers = ref<T[]>([])
   const title = ref<string>('')

   const selectedUserIds = ref<Array<number>>([])
   const selectedUsers = ref<Array<T>>([])
   const selectedUser = ref<any>({})
   const isPending = ref(false)
   const cacheBuster = ref(0)

   const parameters = ref({
      page: 1,
      per_page: 10,
      order_direction: 'asc',
      group_by: [],
      order_by: 'last_name',
      selected_roles: '',
      selected_locals: '',
      selected_crafts: '',
      search: '',
      status: 1,
      craft_id: 0,
      watch: [cacheBuster.value]
   })

   const userId = ref<number>(0)
   const apiUrl = computed(() => `${config.public.API_URL}/api/users/${userId.value === 0 ? '' : userId.value}` )
   const apiUrl_setCraft = computed(() => `${config.public.API_URL}/api/user/user_craft_update`)
   
   function $reset(keepCurrent = false): void {
      if (!keepCurrent) current.value = {} as T
      users.value = []
      user.value = {} as T
      tempUser.value = {} as T
      selectedUserIds.value = []
      selectedUsers.value = []
      selectedUser.value = {}
      isPending.value = false
      total.value = 0
      cacheBuster.value = 0
   }

   const isAdmin = computed((): boolean => {
      return current.value.role_id === 1 || current.value.role_id === 2
   })

   async function fetchLoginUsers(): Promise<void> {
      loginUsers.value = [
         /* {
            id: 1,
            role_id: 1,
            local_id: 1,
            craft_id: 1,
            member_id: '00000001',
            first_name: 'Super Admin',
            last_name: 'UA',
            username: 'Super Admin',
            method: 4,
            email: 'sadmin@uanet.org',
            phone: ''
         },
         {
            id: 2,
            role_id: 2,
            local_id: 2,
            craft_id: 1,
            member_id: '00000002',
            first_name: 'Local Admin',
            last_name: 'Taylor',
            username: 'Local Admin',
            method: 4,
            email: 'ttaylor@uanet.org',
            phone: ''
         },
         {
            id: 3,
            role_id: 3,
            local_id: 2,
            craft_id: 2,
            member_id: '00000003',
            first_name: 'Apprentice',
            last_name: 'Cornelius',
            username: 'Apprentice',
            method: 4,
            email: 'rcornelius@uanet.org',
            phone: ''
         } */
      ]
   }

   const { data: fetchByIdData, error: fetchByIdError, execute: execFetchById } = useFetch(() => { return apiUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: parameters,
      immediate: false,
      watch: false,
   })
   async function fetchById(id: number): Promise<void> {
      console.log(`userStore.fetchById(${id}) - ${isPending.value}`)
      if (isPending.value || id <= 0) return

      isPending.value = true
      cacheBuster.value += 1
      userId.value = id

      await execFetchById()

      if (fetchByIdError.value) {
         console.log('userStore.fetchById().fetchByIdError:', fetchByIdError.value as ErrorT)
         showError(fetchByIdError.value as ErrorT)
      }
      else if (fetchByIdData.value) {
         console.log('userStore.fetchById().fetchByIdData:', fetchByIdData.value)
         selectedUser.value = { ...fetchByIdData.value }
      }

      isPending.value = false
   }

   function getByEmail(email: string, id: number): T {
      return users.value.find((row: T) => row.email === email && row.id !== id) as T
   }

   function getByMemberNum(member_id: string, id: number): T {
      return users.value.find((row: T) => row.member_id === member_id && row.id !== id) as T
   }

   function setUserRoleId(role_id: number) {
      console.log('user.setTempUser()')
      current.value.role_id = role_id
   }

   const { data: setCurrentData, error: setCurrentError, execute: execSetCurrent } = useFetch(`${config.public.API_URL}/api/user/userinfo`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })
   async function fetchUser(): Promise<any> {
      console.log(`fetchUser()`)
      const { isAuthenticated } = storeToRefs(authStore)
      console.log('user isAuthenticated:', isAuthenticated.value)
      if (isPending.value) return ''

      isPending.value = true
      cacheBuster.value += 1

      await execSetCurrent()

      if (setCurrentError.value) {
         console.log('userStore.fetchUser().setCurrentError:', setCurrentError.value as ErrorT)
         showError(setCurrentError.value as ErrorT)
         isAuthenticated.value = false
      } else if (setCurrentData.value) {
         console.log('userStore.fetchUser().setCurrentData:', setCurrentData.value.message)
         current.value = { ...setCurrentData.value.message } as T
         isAuthenticated.value = true
      }

      isPending.value = false
      return isAuthenticated.value ? setCurrentData.value : ''
   }

   const { data: fetchAllUsersData, error: fetchAllUsersError, execute: execFetchAllUsers } = useFetch(`${config.public.API_URL}/api/user/list`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: parameters,
      immediate: false,
      watch: false,
   })
   async function fetchAllUsers(roles: Array<number> = [], locals: Array<number> = [], crafts: Array<number> = []): Promise<void> {
      console.log(`userStore.fetchAllUsers()`)
      if (isPending.value) return

      isPending.value = true
      cacheBuster.value += 1
      parameters.value.status = 1
      
      if (roles.length > 0)
         parameters.value.selected_roles = JSON.stringify(roles)
      if (locals.length > 0)
         parameters.value.selected_locals = JSON.stringify(locals)
      if (crafts.length > 0)
         parameters.value.selected_crafts = JSON.stringify(crafts)

      await execFetchAllUsers()

      if (fetchAllUsersError.value) {
         console.log('userStore.fetchAllUsers().fetchAllUsersError:', fetchAllUsersError.value as ErrorT)
         showError(fetchAllUsersError.value as ErrorT)
      } else if (fetchAllUsersData.value) {
         console.log('userStore.fetchAllUsers().fetchAllUsersData:', fetchAllUsersData.value.data)
         users.value = []
         users.value = fetchAllUsersData.value.data.slice()
         total.value = fetchAllUsersData.value.total
      }

      isPending.value = false
   }

   const { data: fetchHiddenUsersData, error: fetchHiddenUsersError, execute: execFetchHiddenUsers } = useFetch(`${config.public.API_URL}/api/user/list`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: parameters,
      immediate: false,
      watch: false,
   })
   async function fetchHiddenUsers(roles: Array<number> = [], locals: Array<number> = []): Promise<void> {
      console.log(`userStore.fetchHiddenUsers()`)
      if (isPending.value) return

      isPending.value = true
      cacheBuster.value += 1
      parameters.value.status = 0
      parameters.value.selected_roles = JSON.stringify(roles)
      parameters.value.selected_locals = JSON.stringify(locals)

      await execFetchHiddenUsers()

      if (fetchHiddenUsersError.value) {
         console.log('userStore.fetchHiddenUsers().fetchHiddenUsersError:', fetchHiddenUsersError.value as ErrorT)
         showError(fetchHiddenUsersError.value as ErrorT)
      } else if (fetchHiddenUsersData.value) {
         console.log('userStore.fetchHiddenUsers().fetchHiddenUsersData:', fetchHiddenUsersData.value.data)
         hiddenUsers.value = []
         hiddenUsers.value = fetchHiddenUsersData.value.data.slice()
         hiddenTotal.value = fetchHiddenUsersData.value.total
      }

      isPending.value = false
   }

   const { data: createData, error: createError, execute: execCreate } = useFetch(`${config.public.API_URL}/api/users`, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: user,
      immediate: false,
      watch: false,
   })
   async function createUser(): Promise<string> {
      console.log(`userStore.createUser()`)
      isPending.value = true
      cacheBuster.value += 1
      
      let msg = 'error'
      user.value = { ...tempUser.value }
      user.value.phone = user.value.phone.replace(/\D/g, '')

      await execCreate()

      if (createError.value) {
         if (createError.value.data.message === 'Duplicate Email') {
            setSnackbar({
               type: `error`,
               text: `The E-mail ${tempUser.value.email} is already in use.`,
            })
         } else if (createError.value.data.message === 'Duplicate Member') {
            setSnackbar({
               type: `error`,
               text: `The member/card number ${tempUser.value.member_id} is already in use.`,
            })
         } else {
            showError(createError.value as ErrorT)
         }
      }
      else if (createData.value) {
         setSnackbar({
            type: `success`,
            text: `New User successfully created!`,
         })
         msg = createData.value.toString()
      }

      isPending.value = false
      return msg
   }

   const formData = ref<FormData>(new FormData())
   const headers_bulk = ref({
      ...headers,
      'Accept': '*/*'
   })
   const { data: createBulkData, error: createBulkError, execute: execCreateBulk } = useFetch(`${config.public.API_URL}/api/users/Bulk`, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers_bulk,
      body: formData,
      immediate: false,
      watch: false,
   })
   async function createBulkUsers(csv: File): Promise<boolean> {
      console.log(`userStore.createBulkUser()`)
      isPending.value = true
      cacheBuster.value += 1
      
      let success = false
      formData.value = new FormData()
      formData.value.append('file', csv)

      await execCreateBulk()

      if (createBulkError.value) {
            showError(createBulkError.value as ErrorT)
      }
      else if (createBulkData.value) {
         setSnackbar({
            type: `success`,
            text: `New users successfully created!`,
         })
         success = true
      }

      isPending.value = false
      return success
   }

   const updateUrl = computed(() => `${config.public.API_URL}/api/users/${tempUser.value ? tempUser.value.id : 0}`)
   const { data: updateData, error: updateError, execute: execUpdate } = useFetch(() => { return updateUrl.value }, {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      body: user,
      immediate: false,
      watch: false,
   })
   async function updateUser(): Promise<boolean> {
      console.log(`userStore.updateUser()`)
      if (isPending.value) return false

      const user_backup = { ...user.value }
      if (tempUser.value.role_id < 3) {
         tempUser.value.craft_id = null
      }
      user.value = { ...tempUser.value }
      user.value.phone = user.value.phone.replace(/\D/g, '')

      if (!user.value) {
         user.value = { ...user_backup }
         return false
      }

      if (user.value.method === 1 || user.value.method === 3) {
          if (user.value.email.trim().length < 5 || !/.+@.+\..+/.test(user.value.email.trim())) {
              setSnackbar({
                 type: `error`,
                 text: `Please enter a valid E-mail address.`,
              })
              user.value = { ...user_backup }
              return false
          }
      }

      const phone = user.value.phone.trim().replace(/\D/g, '');

      if (user.value.method === 2 || user.value.method === 3) {
          if (phone.length !== 10 || !/^[2-9][0-9]{2}[2-9][0-9]{6}$/.test(phone)) {
              setSnackbar({
                 type: `error`,
                 text: `Please enter 10-digit phone number.`,
              })
              user.value = { ...user_backup }
              return false
          }
      }
      else if ((user.value.method === 1 || user.value.method === 4) && phone !== null && phone.length > 0) {
         if (phone.length !== 10 || !/^[2-9][0-9]{2}[2-9][0-9]{6}$/.test(phone)) {
            setSnackbar({
               type: `error`,
               text: `Please enter 10-digit phone number or clear its field.`,
            })
            user.value = { ...user_backup }
            return false
        }
      }

      user.value.phone = phone

      isPending.value = true

      let success = false
      await execUpdate()

      if (updateError.value) {
         console.log('userStore.updateUser().updateError:', updateError.value as ErrorT)
         if (updateError.value.data.message === 'Duplicate Email') {
            setSnackbar({
               type: `error`,
               text: `The E-mail ${user.value.email} is already in use.`,
            })
         } else if (updateError.value.data.message === 'Duplicate Member') {
            setSnackbar({
               type: `error`,
               text: `The member/card number ${user.value.member_id} is already in use.`,
            })
         } else {
            showError(updateError.value as ErrorT)
         }
         user.value = { ...user_backup }
         isPending.value = false
         return false
      } else if (updateData.value) {
         console.log('userStore.updateUser().updateData:', updateData.value)
         await fetchAllUsers()

         setSnackbar({
            type: `success`,
            text: `User updated successfully`,
         })
         success = true
      }

      isPending.value = false
      return success
   }

   const setCraftObj = ref({})
   const setCraftBody = computed(() => setCraftObj.value)
   const { data: setCraftData, error: setCraftError, execute: execSetCraft } = useFetch(() => { return apiUrl_setCraft.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: setCraftBody,
      immediate: false,
      watch: false,
   })
   async function setCraft(craft_id: number): Promise<boolean> {
      console.log(`userStore.setCraft(user ${craft_id})`)
      if (isPending.value) return false

      isPending.value = true
      setCraftObj.value = {
         craft_id: craft_id
      }
      let success = false

      await execSetCraft()

      if (setCraftError.value) {
         console.log('userStore.setCraft().setCraftError:', setCraftError.value as ErrorT)
         if (setCraftError.value.data.message === 'Craft already set for user') {
            setSnackbar({
               type: `error`,
               text: `This account has an assigned craft.  Contact the administrator for assistance.`,
            })
         } else if (setCraftError.value.data.message === 'Craft is not available to user') {
            setSnackbar({
               type: `error`,
               text: `The selected craft is not available.`,
            })
         } else {
            showError(setCraftError.value as ErrorT)
         }
         success = false
      }
      else if (setCraftData.value) {
         console.log('userStore.setCraft().setCraftData:', setCraftData.value)
         setSnackbar({
            type: `success`,
            text: `Craft successfully assigned.`,
         })
         current.value.craft_id = craft_id
         success = true
      }

      isPending.value = false
      return success
   }

   const deletedID = ref(0)
   const deletedURL = computed(() => `${config.public.API_URL}/api/users/${deletedID.value ? deletedID.value : 0}`)

   const { data: deletedData, error: deletedError, execute: execDeleted } = useFetch(() => { return deletedURL.value }, {
      method: 'delete',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })
   async function deleteUser(id: number) {
      console.log(`deleteUser(${id})`)

      deletedID.value = id

      await execDeleted()

      if (deletedError.value) {
         showError(deletedError.value as ErrorT)
      } else if (deletedData.value) {
         console.log(`User deleted!`)
         setSnackbar({
            type: `success`,
            text: 'User has been deleted.',
         })
      }
      isPending.value = false
   }

   const readUrl = computed(() => `${config.public.API_URL}/api/users/${userId.value}`)
   const { data: readData, error: readError, execute: execRead } = useFetch(() => { return readUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })
   async function readRow(id: number): Promise<T | null> {
      console.log(`userStore.readRow()`)
      if (isPending.value || id <= 0) return null

      isPending.value = true
      cacheBuster.value += 1
      userId.value = id
      let found: T | null = null

      await execRead()

      if (readError.value) {
         console.log('userStore.fetchAllUsers().readError:', readError.value as ErrorT)
         showError(readError.value as ErrorT)
      } else if (readData.value) {
         console.log('userStore.fetchAllUsers().readData:', readData.value)
         found = readData.value.message as T
      }

      isPending.value = false
      return found
   }

   const restoreObj = ref({})
   const restoreBody = computed(() => restoreObj.value)

   const restoreURL = computed(() => `${config.public.API_URL}/api/user/bulk_restore`)
   const { data: restoreData, error: restoreError, execute: execRestore } = useFetch(() => { return restoreURL.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: restoreBody,
      immediate: false,
      watch: false,
   })

   async function restoreUsers(ids: Array<number>) {
      console.log('usersStore.restoreUsers()')
      if (isPending.value) return

      isPending.value = true
      restoreObj.value = {
         user_list: ids,
      }

      cacheBuster.value += 1
      await execRestore()

      if (restoreError.value) {
         console.log('usersStore.restoreUsers().restoreError:', restoreError.value as ErrorT)
         showError(restoreError.value as ErrorT)
      } else if (restoreData.value) {
         console.log('usersStore.restoreUsers().restoreData:', restoreData.value)
         setSnackbar({
            type: `success`,
            text: 'User(s) successfully restored.',
         })
      }
      isPending.value = false
   }

   return {
      user,
      users,
      current,
      tempUser,
      isAdmin,
      isPending,
      parameters,
      total,
      selectedUserIds,
      selectedUsers,
      selectedUser,
      hiddenUsers,
      hiddenTotal,
      loginUsers,
      title,
      fetchUser,
      setUserRoleId,
      fetchById,
      getByEmail,
      getByMemberNum,
      fetchAllUsers,
      fetchHiddenUsers,
      createUser,
      createBulkUsers,
      updateUser,
      restoreUsers,
      deleteUser,
      readRow,
      $reset,
      fetchLoginUsers,
      setCraft
   }
},
   {
      persist: {
         storage: localStorage
      }
   }
)

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
