import table_data from '~/data/page_table.json'
import { useCraftStore } from '~/stores/craft'
import { useEvaluationStore } from '~/stores/evaluation'
import { useGroupStore } from '~/stores/group'
import { useHoursReportStore } from '~/stores/hoursReport'
import { useLocalStore } from '~/stores/local'
import { useTaskStore } from '~/stores/task'
import type { Page as T } from '~/types'

export const usePageStore = defineStore('pageStore', () => {
   const table = ref<T[]>(table_data)
   const changesExist = ref<boolean>(false)
   const isForceNav = ref<boolean>(false)
   const isWarningShown = ref<boolean>(false)
   const tempNavTo = ref<Object | null>(null)
   const isPending = ref<boolean>(false)
   const loadId = ref<number>(0)

   function $reset(): void {
      changesExist.value = false
      isForceNav.value = false
      isWarningShown.value = false
      tempNavTo.value = null
      isPending.value = false
   }

   function getLoadId(): number {
      const temp_loadId = loadId.value
      loadId.value = 0
      return temp_loadId
   }

   function setLoadId(id: number): void {
      loadId.value = id
   }

   function getById(id: number): T {
      return table.value.find((row: T) => row.id === id) as T
   }

   function getBodyById(id: number): string {
      return table.value.find((row: T) => row.id === id)?.body as string
   }

   function getIconById(id: number): string {
      return table.value.find((row: T) => row.id === id)?.icon as string
   }

   function getNameById(id: number): string {
      return table.value.find((row: T) => row.id === id)?.name as string
   }
      
   function resetStoreFilters(): void {
      console.log('pageStore.resetStoreFilters()')
      useCraftStore().$reset()
      useEvaluationStore().$reset()
      useGroupStore().$reset()
      useHoursReportStore().$reset()
      useLocalStore().$reset()
      useTaskStore().$reset()
   }

   return {
      table, changesExist, isForceNav, isWarningShown, tempNavTo,
      getById, getBodyById, getIconById, getNameById, resetStoreFilters, $reset, getLoadId, setLoadId
   }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(usePageStore, import.meta.hot))
}