export const userStatuses = [
   { title: 'Archived', value: 0 },
   { title: 'Active', value: 1 },
   { title: 'Blocked', value: 2 },
]

export const penaltyTask = {
   id: 1,
   name: 'Penalty',
   craft_id: 1,
   local_id: 1,
   'non-ojt': 0,
   status: 0
}

export const localIdsAllowingPenalties = [909]

export const formRules = {
   required: [(v: string) => !!v || 'This field is required'],
   name: [(v: string) => !!v || 'Name is required'],
   displayName: [(v: string) => !!v || 'Display Name is required'],
   min: [(v: string) => (v && v.length >= 8) || 'Minimum of 8 characters'],
   maxDigit: [(v: string) => (v === undefined || v === null || v.length <= 11) || 'Maximum of 11 digits'],
   email: [(v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
   zip: [(v: string) => /^[0-9]{5}$/.test(v) || 'Zip code must be valid'],
   localNum: [(v: string) => /^[0-9]{3}$/.test(v) || 'Must be 3 digits long'],
   positiveNum: [(v: string) => /^[0-9]$|^[1-9]+[0-9]*$/.test(v) || 'Must be a positive number'],
   phone: [(v: string) => (v === undefined || v === null || v.trim() === '' || /^[2-9][0-9]{2}[2-9][0-9]{6}$/.test(!v ? v : v.replace(/\D/g, ''))) || 'Must be a 10-digit phone number'],
   phoneReq: [(v: string) => /^[2-9][0-9]{2}[2-9][0-9]{6}$/.test(v.replace(/\D/g, '')) || 'Must be a 10-digit phone number'],
   file: [(v: any) => !v || !v.length || v[0].size < 200000000 || 'File size should be less than 200 MB!']
}

export const itemsPerPageOptions = [
   { value: 5, title: '5' },
   { value: 10, title: '10' },
   { value: 25, title: '25' },
   { value: 50, title: '50' },
   { value: 100, title: '100' },
]

export const states = [
   { title: 'Alabama', value: 'AL' },
   { title: 'Alaska', value: 'AK' },
   { title: 'Arizona', value: 'AZ' },
   { title: 'Arkansas', value: 'AR' },
   { title: 'California', value: 'CA' },
   { title: 'Colorado', value: 'CO' },
   { title: 'Connecticut', value: 'CT' },
   { title: 'Delaware', value: 'DE' },
   { title: 'District of Columbia', value: 'DC' },
   { title: 'Florida', value: 'FL' },
   { title: 'Georgia', value: 'GA' },
   { title: 'Hawaii', value: 'HI' },
   { title: 'Idaho', value: 'ID' },
   { title: 'Illinois', value: 'IL' },
   { title: 'Indiana', value: 'IN' },
   { title: 'Iowa', value: 'IA' },
   { title: 'Kansas', value: 'KS' },
   { title: 'Kentucky', value: 'KY' },
   { title: 'Louisiana', value: 'LA' },
   { title: 'Maine', value: 'ME' },
   { title: 'Maryland', value: 'MD' },
   { title: 'Massachusetts', value: 'MA' },
   { title: 'Michigan', value: 'MI' },
   { title: 'Minnesota', value: 'MN' },
   { title: 'Mississippi', value: 'MS' },
   { title: 'Missouri', value: 'MO' },
   { title: 'Montana', value: 'MT' },
   { title: 'Nebraska', value: 'NE' },
   { title: 'Nevada', value: 'NV' },
   { title: 'New Hampshire', value: 'NH' },
   { title: 'New Jersey', value: 'NJ' },
   { title: 'New Mexico', value: 'NM' },
   { title: 'New York', value: 'NY' },
   { title: 'North Carolina', value: 'NC' },
   { title: 'North Dakota', value: 'ND' },
   { title: 'Ohio', value: 'OH' },
   { title: 'Oklahoma', value: 'OK' },
   { title: 'Oregon', value: 'OR' },
   { title: 'Pennsylvania', value: 'PA' },
   { title: 'Rhode Island', value: 'RI' },
   { title: 'South Carolina', value: 'SC' },
   { title: 'South Dakota', value: 'SD' },
   { title: 'Tennessee', value: 'TN' },
   { title: 'Texas', value: 'TX' },
   { title: 'Utah', value: 'UT' },
   { title: 'Vermont', value: 'VT' },
   { title: 'Virginia', value: 'VA' },
   { title: 'Washington', value: 'WA' },
   { title: 'West Virginia', value: 'WV' },
   { title: 'Wisconsin', value: 'WI' },
   { title: 'Alberta', value: 'AB' },
   { title: 'British Columbia', value: 'BC' },
   { title: 'Manitoba', value: 'MB' },
   { title: 'New Brunswick', value: 'NB' },
   { title: 'Newfoundland and Labrador', value: 'NL' },
   { title: 'Nova Scotia', value: 'NS' },
   { title: 'Ontario', value: 'ON' },
   { title: 'Prince Edward Island', value: 'PE' },
   { title: 'Quebec', value: 'QC' },
   { title: 'Saskatchewan', value: 'SK' }
]

export const countries = [
   { value: '', title: '- None -' },
   { value: 'AF', title: 'Afghanistan' },
   { value: 'AX', title: 'Aland Islands' },
   { value: 'AL', title: 'Albania' },
   { value: 'DZ', title: 'Algeria' },
   { value: 'AS', title: 'American Samoa' },
   { value: 'AD', title: 'Andorra' },
   { value: 'AO', title: 'Angola' },
   { value: 'AI', title: 'Anguilla' },
   { value: 'AQ', title: 'Antarctica' },
   { value: 'AG', title: 'Antigua and Barbuda' },
   { value: 'AR', title: 'Argentina' },
   { value: 'AM', title: 'Armenia' },
   { value: 'AW', title: 'Aruba' },
   { value: 'AU', title: 'Australia' },
   { value: 'AT', title: 'Austria' },
   { value: 'AZ', title: 'Azerbaijan' },
   { value: 'BS', title: 'Bahamas' },
   { value: 'BH', title: 'Bahrain' },
   { value: 'BD', title: 'Bangladesh' },
   { value: 'BB', title: 'Barbados' },
   { value: 'BY', title: 'Belarus' },
   { value: 'BE', title: 'Belgium' },
   { value: 'BZ', title: 'Belize' },
   { value: 'BJ', title: 'Benin' },
   { value: 'BM', title: 'Bermuda' },
   { value: 'BT', title: 'Bhutan' },
   { value: 'BO', title: 'Bolivia' },
   { value: 'BA', title: 'Bosnia and Herzegovina' },
   { value: 'BW', title: 'Botswana' },
   { value: 'BV', title: 'Bouvet Island' },
   { value: 'BR', title: 'Brazil' },
   { value: 'IO', title: 'British Indian Ocean Territory' },
   { value: 'VG', title: 'British Virgin Islands' },
   { value: 'BN', title: 'Brunei' },
   { value: 'BG', title: 'Bulgaria' },
   { value: 'BF', title: 'Burkina Faso' },
   { value: 'BI', title: 'Burundi' },
   { value: 'KH', title: 'Cambodia' },
   { value: 'CM', title: 'Cameroon' },
   { value: 'CA', title: 'Canada' },
   { value: 'CV', title: 'Cape Verde' },
   { value: 'BQ', title: 'Caribbean Netherlands' },
   { value: 'KY', title: 'Cayman Islands' },
   { value: 'CF', title: 'Central African Republic' },
   { value: 'TD', title: 'Chad' },
   { value: 'CL', title: 'Chile' },
   { value: 'CN', title: 'China' },
   { value: 'CX', title: 'Christmas Island' },
   { value: 'CC', title: 'Cocos (Keeling) Islands' },
   { value: 'CO', title: 'Colombia' },
   { value: 'KM', title: 'Comoros' },
   { value: 'CG', title: 'Congo (Brazzaville)' },
   { value: 'CD', title: 'Congo (Kinshasa)' },
   { value: 'CK', title: 'Cook Islands' },
   { value: 'CR', title: 'Costa Rica' },
   { value: 'HR', title: 'Croatia' },
   { value: 'CU', title: 'Cuba' },
   { value: 'CW', title: 'Curaçao' },
   { value: 'CY', title: 'Cyprus' },
   { value: 'CZ', title: 'Czech Republic' },
   { value: 'DK', title: 'Denmark' },
   { value: 'DJ', title: 'Djibouti' },
   { value: 'DM', title: 'Dominica' },
   { value: 'DO', title: 'Dominican Republic' },
   { value: 'EC', title: 'Ecuador' },
   { value: 'EG', title: 'Egypt' },
   { value: 'SV', title: 'El Salvador' },
   { value: 'GQ', title: 'Equatorial Guinea' },
   { value: 'ER', title: 'Eritrea' },
   { value: 'EE', title: 'Estonia' },
   { value: 'ET', title: 'Ethiopia' },
   { value: 'FK', title: 'Falkland Islands' },
   { value: 'FO', title: 'Faroe Islands' },
   { value: 'FJ', title: 'Fiji' },
   { value: 'FI', title: 'Finland' },
   { value: 'FR', title: 'France' },
   { value: 'GF', title: 'French Guiana' },
   { value: 'PF', title: 'French Polynesia' },
   { value: 'TF', title: 'French Southern Territories' },
   { value: 'GA', title: 'Gabon' },
   { value: 'GM', title: 'Gambia' },
   { value: 'GE', title: 'Georgia' },
   { value: 'DE', title: 'Germany' },
   { value: 'GH', title: 'Ghana' },
   { value: 'GI', title: 'Gibraltar' },
   { value: 'GR', title: 'Greece' },
   { value: 'GL', title: 'Greenland' },
   { value: 'GD', title: 'Grenada' },
   { value: 'GP', title: 'Guadeloupe' },
   { value: 'GU', title: 'Guam' },
   { value: 'GT', title: 'Guatemala' },
   { value: 'GG', title: 'Guernsey' },
   { value: 'GN', title: 'Guinea' },
   { value: 'GW', title: 'Guinea-Bissau' },
   { value: 'GY', title: 'Guyana' },
   { value: 'HT', title: 'Haiti' },
   { value: 'HM', title: 'Heard Island and McDonald Islands' },
   { value: 'HN', title: 'Honduras' },
   { value: 'HK', title: 'Hong Kong S.A.R., China' },
   { value: 'HU', title: 'Hungary' },
   { value: 'IS', title: 'Iceland' },
   { value: 'IN', title: 'India' },
   { value: 'ID', title: 'Indonesia' },
   { value: 'IR', title: 'Iran' },
   { value: 'IQ', title: 'Iraq' },
   { value: 'IE', title: 'Ireland' },
   { value: 'IM', title: 'Isle of Man' },
   { value: 'IL', title: 'Israel' },
   { value: 'IT', title: 'Italy' },
   { value: 'CI', title: 'Ivory Coast' },
   { value: 'JM', title: 'Jamaica' },
   { value: 'JP', title: 'Japan' },
   { value: 'JE', title: 'Jersey' },
   { value: 'JO', title: 'Jordan' },
   { value: 'KZ', title: 'Kazakhstan' },
   { value: 'KE', title: 'Kenya' },
   { value: 'KI', title: 'Kiribati' },
   { value: 'KW', title: 'Kuwait' },
   { value: 'KG', title: 'Kyrgyzstan' },
   { value: 'LA', title: 'Laos' },
   { value: 'LV', title: 'Latvia' },
   { value: 'LB', title: 'Lebanon' },
   { value: 'LS', title: 'Lesotho' },
   { value: 'LR', title: 'Liberia' },
   { value: 'LY', title: 'Libya' },
   { value: 'LI', title: 'Liechtenstein' },
   { value: 'LT', title: 'Lithuania' },
   { value: 'LU', title: 'Luxembourg' },
   { value: 'MO', title: 'Macao S.A.R., China' },
   { value: 'MK', title: 'Macedonia' },
   { value: 'MG', title: 'Madagascar' },
   { value: 'MW', title: 'Malawi' },
   { value: 'MY', title: 'Malaysia' },
   { value: 'MV', title: 'Maldives' },
   { value: 'ML', title: 'Mali' },
   { value: 'MT', title: 'Malta' },
   { value: 'MH', title: 'Marshall Islands' },
   { value: 'MQ', title: 'Martinique' },
   { value: 'MR', title: 'Mauritania' },
   { value: 'MU', title: 'Mauritius' },
   { value: 'YT', title: 'Mayotte' },
   { value: 'MX', title: 'Mexico' },
   { value: 'FM', title: 'Micronesia' },
   { value: 'MD', title: 'Moldova' },
   { value: 'MC', title: 'Monaco' },
   { value: 'MN', title: 'Mongolia' },
   { value: 'ME', title: 'Montenegro' },
   { value: 'MS', title: 'Montserrat' },
   { value: 'MA', title: 'Morocco' },
   { value: 'MZ', title: 'Mozambique' },
   { value: 'MM', title: 'Myanmar' },
   { value: 'NA', title: 'Namibia' },
   { value: 'NR', title: 'Nauru' },
   { value: 'NP', title: 'Nepal' },
   { value: 'NL', title: 'Netherlands' },
   { value: 'AN', title: 'Netherlands Antilles' },
   { value: 'NC', title: 'New Caledonia' },
   { value: 'NZ', title: 'New Zealand' },
   { value: 'NI', title: 'Nicaragua' },
   { value: 'NE', title: 'Niger' },
   { value: 'NG', title: 'Nigeria' },
   { value: 'NU', title: 'Niue' },
   { value: 'NF', title: 'Norfolk Island' },
   { value: 'MP', title: 'Northern Mariana Islands' },
   { value: 'KP', title: 'North Korea' },
   { value: 'NO', title: 'Norway' },
   { value: 'OM', title: 'Oman' },
   { value: 'PK', title: 'Pakistan' },
   { value: 'PW', title: 'Palau' },
   { value: 'PS', title: 'Palestinian Territory' },
   { value: 'PA', title: 'Panama' },
   { value: 'PG', title: 'Papua New Guinea' },
   { value: 'PY', title: 'Paraguay' },
   { value: 'PE', title: 'Peru' },
   { value: 'PH', title: 'Philippines' },
   { value: 'PN', title: 'Pitcairn' },
   { value: 'PL', title: 'Poland' },
   { value: 'PT', title: 'Portugal' },
   { value: 'PR', title: 'Puerto Rico' },
   { value: 'QA', title: 'Qatar' },
   { value: 'RE', title: 'Reunion' },
   { value: 'RO', title: 'Romania' },
   { value: 'RU', title: 'Russia' },
   { value: 'RW', title: 'Rwanda' },
   { value: 'BL', title: 'Saint Barthélemy' },
   { value: 'SH', title: 'Saint Helena' },
   { value: 'KN', title: 'Saint Kitts and Nevis' },
   { value: 'LC', title: 'Saint Lucia' },
   { value: 'MF', title: 'Saint Martin (French part)' },
   { value: 'PM', title: 'Saint Pierre and Miquelon' },
   { value: 'VC', title: 'Saint Vincent and the Grenadines' },
   { value: 'WS', title: 'Samoa' },
   { value: 'SM', title: 'San Marino' },
   { value: 'ST', title: 'Sao Tome and Principe' },
   { value: 'SA', title: 'Saudi Arabia' },
   { value: 'SN', title: 'Senegal' },
   { value: 'RS', title: 'Serbia' },
   { value: 'SC', title: 'Seychelles' },
   { value: 'SL', title: 'Sierra Leone' },
   { value: 'SG', title: 'Singapore' },
   { value: 'SX', title: 'Sint Maarten' },
   { value: 'SK', title: 'Slovakia' },
   { value: 'SI', title: 'Slovenia' },
   { value: 'SB', title: 'Solomon Islands' },
   { value: 'SO', title: 'Somalia' },
   { value: 'ZA', title: 'South Africa' },
   { value: 'GS', title: 'South Georgia and the South Sandwich Islands' },
   { value: 'KR', title: 'South Korea' },
   { value: 'SS', title: 'South Sudan' },
   { value: 'ES', title: 'Spain' },
   { value: 'LK', title: 'Sri Lanka' },
   { value: 'SD', title: 'Sudan' },
   { value: 'SR', title: 'Suriname' },
   { value: 'SJ', title: 'Svalbard and Jan Mayen' },
   { value: 'SZ', title: 'Swaziland' },
   { value: 'SE', title: 'Sweden' },
   { value: 'CH', title: 'Switzerland' },
   { value: 'SY', title: 'Syria' },
   { value: 'TW', title: 'Taiwan' },
   { value: 'TJ', title: 'Tajikistan' },
   { value: 'TZ', title: 'Tanzania' },
   { value: 'TH', title: 'Thailand' },
   { value: 'TL', title: 'Timor-Leste' },
   { value: 'TG', title: 'Togo' },
   { value: 'TK', title: 'Tokelau' },
   { value: 'TO', title: 'Tonga' },
   { value: 'TT', title: 'Trinidad and Tobago' },
   { value: 'TN', title: 'Tunisia' },
   { value: 'TR', title: 'Turkey' },
   { value: 'TM', title: 'Turkmenistan' },
   { value: 'TC', title: 'Turks and Caicos Islands' },
   { value: 'TV', title: 'Tuvalu' },
   { value: 'VI', title: 'U.S. Virgin Islands' },
   { value: 'UG', title: 'Uganda' },
   { value: 'UA', title: 'Ukraine' },
   { value: 'AE', title: 'United Arab Emirates' },
   { value: 'GB', title: 'United Kingdom' },
   { value: 'US', title: 'United States' },
   { value: 'UM', title: 'United States Minor Outlying Islands' },
   { value: 'UY', title: 'Uruguay' },
   { value: 'UZ', title: 'Uzbekistan' },
   { value: 'VU', title: 'Vanuatu' },
   { value: 'VA', title: 'Vatican' },
   { value: 'VE', title: 'Venezuela' },
   { value: 'VN', title: 'Vietnam' },
   { value: 'WF', title: 'Wallis and Futuna' },
   { value: 'EH', title: 'Western Sahara' },
   { value: 'YE', title: 'Yemen' },
   { value: 'ZM', title: 'Zambia' },
   { value: 'ZW', title: 'Zimbabwe' },
]
