import type { Evaluation as T, ErrorT} from '~/types'

export const useEvaluationStore = defineStore('evaluationStore', () => {
    const config = useRuntimeConfig()
    const headers = computed(() => { return useAuthStore().headers })
    const { setSnackbar } = useSnackbarStore()
    const { showError } = useAPI()
    const evaluations = ref<T[]>([])
    const evaluation = ref<T>({} as T)
    const evaluation_info = ref<boolean>(window.evaluation_info ? window.evaluation_info : '')
    const isPending = ref(false)
    const total = ref(0)
    const questions = ref<Array<any>>([])
    const cacheBuster = ref(0)
    const parameters = ref({
        page: 1,
        perPage: Number.MAX_SAFE_INTEGER,
        order_direction: 'desc',
        watch: [cacheBuster.value]
    })

    const paramsWithLocalId = ref({ ...parameters.value, local_id: 0, questions: '[]' })
    const paramsWithUserId = ref({ ...parameters.value, user_id: 0 })

    const itemId = ref<number>(0)
    const itemBody = ref<FormData | any>({})

    function $reset(): void {
      evaluations.value = []
      evaluation.value = {} as T
      evaluation_info.value = window.evaluation_info ? window.evaluation_info : ''
      total.value = 0
      questions.value = []
      isPending.value = false
    }

    const apiUrl = computed(() => `${config.public.API_URL}/api/evaluations/${itemId.value === 0 ? '' : itemId.value}` )
    const apiUrl_questions = computed(() => `${config.public.API_URL}/api/questions/` )
    const apiUrl_questionsUpdate = computed(() => `${config.public.API_URL}/api/questions_update` )
    const apiUrl_evalUrl = computed(() => `${config.public.API_URL}/api/evaluation_url` )
    const apiUrl_evalPost = computed(() => `${config.public.API_URL}/outside_evaluation_post` )
 
    const { data: fetchEvalsData, error: fetchEvalsError, execute: execFetchEvals } = useFetch(() => { return apiUrl.value }, {
        method: 'GET',
        baseURL: config.public.API_URL,
        headers: headers,
        params: parameters,
        immediate: false,
        watch: false,
     })
     async function fetchEvals(evaluation_id: number = 0): Promise<void> {
      console.log('evaluationStore.fetchEvals()', evaluation_id)
         if (isPending.value) return
  
         isPending.value = true
         cacheBuster.value += 1
  
         itemId.value = 0
   
         await execFetchEvals()
   
         if (fetchEvalsError.value) {
            console.log('evaluationStore.fetchEvals().fetchEvalsError:', fetchEvalsError.value as ErrorT)   
            showError(fetchEvalsError.value as ErrorT)
         }
         else if (fetchEvalsData.value) {
            console.log('evaluationStore.fetchEvals().fetchEvalsData:', fetchEvalsData.value)
            evaluations.value = fetchEvalsData.value.data as Array<T>
            if (evaluation_id > 0) evaluation.value = evaluations.value.find(e => e.id === evaluation_id) as T
            else evaluation.value = evaluations.value[0] as T
            total.value = fetchEvalsData.value.total
         }
  
         isPending.value = false
     }

     const { data: fetchEvalsByUserIdData, error: fetchEvalsByUserIdError, execute: execFetchEvalsByUserId } = useFetch(() => { return apiUrl.value }, {
         method: 'GET',
         baseURL: config.public.API_URL,
         headers: headers,
         params: paramsWithUserId,
         immediate: false,
         watch: false,
      })
     async function fetchEvalsByUserId(user_id: number, evaluation_id: number = 0): Promise<void> {
         console.log('evaluationStore.fetchElavsByUserId()', user_id)
         if (isPending.value) return
   
         isPending.value = true
         cacheBuster.value += 1
 
         paramsWithUserId.value.user_id = user_id

         await execFetchEvalsByUserId()
   
         if (fetchEvalsByUserIdError.value) {
            console.log('evaluationStore.fetchElavsByUserId().fetchEvalsByUserIdError:', fetchEvalsByUserIdError.value as ErrorT)   
            showError(fetchEvalsByUserIdError.value as ErrorT)
         }
         else if (fetchEvalsByUserIdData.value) {
            console.log('evaluationStore.fetchElavsByUserId().fetchEvalsByUserIdData:', fetchEvalsByUserIdData.value)
            evaluations.value = fetchEvalsByUserIdData.value.data as Array<T>
            if (evaluation_id > 0) evaluation.value = evaluations.value.find(e => e.id === evaluation_id) as T
            else evaluation.value = evaluations.value[0] as T
            total.value = fetchEvalsByUserIdData.value.total
         }
         
         isPending.value = false
     }

     const { data: fetchQuestionsData, error: fetchQuestionsError, execute: execFetchQuestions } = useFetch(() => { return apiUrl_questions.value }, {
        method: 'GET',
        baseURL: config.public.API_URL,
        headers: headers,
        params: paramsWithLocalId,
        immediate: false,
        watch: false,
     })
     async function fetchQuestions(local_id: number): Promise<void> {
      console.log('evaluationStore.fetchQuestions()', local_id)
        if (isPending.value) return

        isPending.value = true
        cacheBuster.value += 1

        paramsWithLocalId.value.local_id = local_id

        await execFetchQuestions()
  
        if (fetchQuestionsError.value) {
         console.log('evaluationStore.fetchQuestions().fetchQuestionsError:', fetchQuestionsError.value as ErrorT)   
           showError(fetchQuestionsError.value as ErrorT)
        }
        else if (fetchQuestionsData.value) {
         console.log('evaluationStore.fetchQuestions().fetchQuestionsData:', fetchQuestionsData.value)
           questions.value = fetchQuestionsData.value as Array<any>
        }
        
        isPending.value = false
     }

     const { data: saveQuestionsData, error: saveQuestionsError, execute: execSaveQuestions } = useFetch(() => { return apiUrl_questionsUpdate.value }, {
        method: 'POST',
        baseURL: config.public.API_URL,
        headers: headers,
        params: paramsWithLocalId,
        body: itemBody,
        immediate: false,
        watch: false,
     })
     async function saveQuestions(local_id: number): Promise<void> {
      console.log('evaluationStore.saveQuestions()', local_id)
        if (isPending.value) return
   
        isPending.value = true
        cacheBuster.value += 1
 
        paramsWithLocalId.value.local_id = local_id

        questions.value.forEach(q => q.required === undefined ? q.required = 1 : q.required = q.required)

        itemBody.value = { questions: questions.value }

        await execSaveQuestions()

        if (saveQuestionsError.value) {
            showError(saveQuestionsError.value as ErrorT)
        } else if (saveQuestionsData.value) {  
           setSnackbar({
              type: `success`,
              text: `Supervisor evaluation questions successfully updated!`,
           })
        }

        isPending.value = false
     }
 
    const { data: getEvalUrlData, error: getEvalUrlError, execute: execGetEvalUrl } = useFetch(() => { return apiUrl_evalUrl.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: itemBody,
      immediate: false,
      watch: false,
   })
   async function getEvalUrl(evaluation_id: number = 0, email: string = 'fake@email.com'): Promise<boolean> {
      console.log('evaluationStore.getEvalUrl()', evaluation_id)
       if (isPending.value) return false

       isPending.value = true
       cacheBuster.value += 1
       let success = false

       itemBody.value = { evaluation_id: evaluation_id, email: email }
 
       await execGetEvalUrl()
 
       if (getEvalUrlError.value) {
         console.log('evaluationStore.getEvalUrl().getEvalUrlError:', getEvalUrlError.value as ErrorT)   
          showError(getEvalUrlError.value as ErrorT)
       }
       else if (getEvalUrlData.value) {
         console.log('evaluationStore.getEvalUrl().getEvalUrlData:', getEvalUrlData.value)
          success = true
       }

       isPending.value = false
       return success
   }

   const formData = ref<FormData>(new FormData())
   const evalPostHeaders = ref({})
   const { data: evalPostData, error: evalPostError, execute: execEvalPost } = useFetch(() => { return apiUrl_evalPost.value }, {
     method: 'POST',
     baseURL: config.public.API_URL,
     headers: evalPostHeaders,
     body: formData,
     immediate: false,
     watch: false,
  })
  async function evalPost(eval_info: any, pdf: any = null): Promise<boolean> {
      evalPostHeaders.value = {
         'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
         'Accept': '*/*'
      }

      console.log('evaluationStore.evalPost()', eval_info)
      if (isPending.value) return false

      if (eval_info.question_answers.some((qa: any) => qa.answer === null || qa.answer === undefined || qa.answer.toString().trim() === '')) {
         console.log('Please respond to all questions')
         setSnackbar({
            type: `error`,
            text: `Please respond to all questions.`,
         })
         return false
      }

      if (eval_info.first_name.trim() === '' || eval_info.last_name.trim() === '') {
         console.log('Please enter reviewer name')
         setSnackbar({
            type: `error`,
            text: `Please enter reviewer name.`,
         })
         return false
      }

      if (eval_info.sendto_phone.trim().replace(/\D/g, '').length !== 10 || !/^[2-9][0-9]{2}[2-9][0-9]{6}$/.test(eval_info.sendto_phone.trim().replace(/\D/g, ''))) {
         console.log('Please enter 10-digit reviewer phone number')
         setSnackbar({
            type: `error`,
            text: `Please enter 10-digit reviewer phone number.`,
         })
         return false
      }

      isPending.value = true
      cacheBuster.value += 1

      let success = false
      formData.value = new FormData()

      const answers_2d_array = eval_info.question_answers.map((qa: any) => { return [qa.id, qa.answer.toString().trim()] })
      for (var i = 0; i < answers_2d_array.length; i++) {
         for (var j = 0; j < answers_2d_array[i].length; j++) {
            if (j === 0)
               formData.value.append(`answers[${i}][id]`, answers_2d_array[i][j])
            else
               formData.value.append(`answers[${i}][answer]`, answers_2d_array[i][j])
         }
      }
      formData.value.append('code', eval_info.code)
      formData.value.append('email', eval_info.sendto_email)
      formData.value.append('evaluation_id', eval_info.id)
      if (pdf) formData.value.append('file', pdf)

      await execEvalPost()

      if (evalPostError.value) {
         console.log('evaluationStore.evalPost().evalPostError:', evalPostError.value as ErrorT)   
         showError(evalPostError.value as ErrorT)
      }
      else if (evalPostData.value) {
         console.log('evaluationStore.evalPost().evalPostData:', evalPostData.value)
         success = true
         setSnackbar({
            type: `success`,
            text: `Evaluation was submitted successfully!.`,
         })
      }

      isPending.value = false
      return success
  }

    return {
        fetchEvals,
        fetchEvalsByUserId,
        fetchQuestions,
        saveQuestions,
        getEvalUrl,
        evalPost,
        evaluations,
        evaluation,
        isPending,
        questions,
        evaluation_info,
        $reset
    }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useEvaluationStore, import.meta.hot))
}