import { useSnackbarStore } from '~/stores/snackbar'
import { useAPI } from '~/composables/useAPI'
import { dateString, timeString } from '~/utils/helpers'
import type { HoursReport as T, ErrorT, HoursLog } from '~/types'
import FileSaver from 'file-saver'
import { useUserStore } from '~/stores/user'

export const useHoursReportStore = defineStore('hoursReportStore', () => {
   const userStore = useUserStore()
   const config = useRuntimeConfig()
   const headers = computed(() => { return useAuthStore().headers })
   const { setSnackbar } = useSnackbarStore()
   const { showError } = useAPI()
   const reports = ref<Array<T>>([])
   const report = ref<T>({} as T)
   const isPending = ref(false)
   const total = ref(0)
   const combinedHours = ref<number>(0)
   const cacheBuster = ref(0)
   const parameters = ref({
      page: 1,
      perPage: Number.MAX_SAFE_INTEGER,
      order_direction: 'desc',
      watch: [cacheBuster.value]
   })

   const params = computed(() => {
      return { ...parameters.value, user_id: 0 }
   })

   const paramsExtenstionDate = computed(() => {
      return { ...parameters.value, extension_date: '' }
   })
   
   function $reset(): void {
      reports.value = []
      report.value = {} as T
      total.value = 0
      combinedHours.value = 0
      isPending.value = false
   }

   const itemId = ref<number>(0)
   const itemBody = ref({})
   const apiUrl = computed(() => `${config.public.API_URL}/api/hourreports/${itemId.value === 0 ? '' : itemId.value}` )
   const apiLogUrl = computed(() => `${config.public.API_URL}/api/hourreport/hours_log_store/${itemId.value}` )
   const apiCombinedHours = computed(() => `${config.public.API_URL}/api/combine_hours/${report.value ? report.value.user_id : userStore.current.id}` )
   const apiUrl_customReport = computed(() => `${config.public.API_URL}/api/hourreport/report` )

   /**********************************
    * Get a list of reports.         *
    * Optionally, a specific user.   *
    * Optionally, a specific report. *
    **********************************/

   const { data: fetchReportsData, error: fetchReportsError, execute: execFetchReports } = useFetch(() => { return apiUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: params,
      immediate: false,
      watch: false,
   })
   async function fetchReports(user_id: number = 0, report_id: number = 0): Promise<void> {
       console.log('hoursReportStore.fetchReports()', user_id, report_id)
       if (isPending.value) return

       isPending.value = true
       cacheBuster.value += 1

       itemId.value = 0
       params.value.user_id = user_id
 
       await execFetchReports()
 
       if (fetchReportsError.value) {
         console.log('hoursReportStore.fetchReports().fetchReportsError:', fetchReportsError.value as ErrorT)
          showError(fetchReportsError.value as ErrorT)
       }
       else if (fetchReportsData.value) {
         console.log('hoursReportStore.fetchReports().fetchReportsData:', fetchReportsData.value)
          reports.value = fetchReportsData.value.data as Array<T>
          if (report_id > 0) report.value = reports.value.find(rep => rep.id === report_id) as T
          else report.value = reports.value[0] as T
          total.value = fetchReportsData.value.total
       }

       isPending.value = false
   }

   const currentReportingPeriod = computed(() => {
      if (reports.value.length === 0) return { start: new Date('1955-11-05'), end: new Date('1955-11-05') }
      return { start: reports.value[0].start_date, end: reports.value[0].end_date }
   })

   const previousReportingPeriod = computed(() => {
      if (reports.value.length < 2) return { start: new Date('1955-11-05'), end: new Date('1955-11-05') }
      return { start: reports.value[1].start_date, end: reports.value[1].end_date }
   })

   function isWithinPeriod(period: any, date: Date): boolean {
      period.start = new Date(period.start)
      period.end = new Date(period.end)
      period.start.setHours(0, 0, 0, 0)
      period.end.setHours(0, 0, 0, 0)
      date.setHours(0, 0, 0, 0)
      return date >= period.start && date < period.end
   }

   /*********************************
    * Update the logs for a report. *
    *********************************/

   const { data: saveLogsData, error: saveLogsError, execute: execSaveLogs } = useFetch(() => { return apiLogUrl.value}, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: itemBody,
      immediate: false,
      watch: false,
   })
   async function saveLogs(report_id: number, logs: Array<HoursLog>): Promise<void> {
      console.log('hoursReportStore.saveLogs()')
      if (isPending.value) return

      isPending.value = true
      cacheBuster.value += 1

      itemId.value = report_id
      itemBody.value = { hours_array: logs.map(l => { return { ...l, day_done: l.day_done_str } }), badreport: report.value.bad_report }

      await execSaveLogs()

      if (saveLogsError.value) {
         console.log('hoursReportStore.saveLogs().saveLogsError:', saveLogsError.value as ErrorT)   
         setSnackbar({
            type: 'error',
            text: `Could not update hours report.`
         })
         showError(saveLogsError.value as ErrorT)
      }
      else if (saveLogsData.value) {
         console.log('hoursReportStore.saveLogs().saveLogsData:', saveLogsData.value)
         setSnackbar({
            type: 'success',
            text: `Hours report successfully updated!`
         })
      }

      isPending.value = false
   }

   const { data: combinedHoursData, error: combinedHoursError, execute: execCombinedHours } = useFetch(() => { return apiCombinedHours.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: params,
      immediate: false,
      watch: false,
   })
   async function updateCombinedHours(): Promise<void> {
      await execCombinedHours()

      if (combinedHoursError.value) {
         console.log('hoursReportStore.combinedHours().combinedHoursError:', combinedHoursError.value as ErrorT)   
         setSnackbar({
            type: 'error',
            text: `Could not update hours report.`
         })
         showError(combinedHoursError.value as ErrorT)
      }
      else if (combinedHoursData.value) {
         console.log('hoursReportStore.combinedHours().combinedHoursData:', combinedHoursData.value)
         combinedHours.value = combinedHoursData.value[0] ? combinedHoursData.value[0].total : 0
         if (!combinedHours.value) combinedHours.value = 0
      }
   }

   const { data: fetchCustomReportData, error: fetchCustomReportError, execute: execFetchCustomReport } = useFetch(() => { return apiUrl_customReport.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      params: parameters,
      body: itemBody,
      immediate: false,
      watch: false,
   })
   async function fetchCustomReport(user_ids: Array<number>, group_ids: Array<number>, start_date: string, end_date: string, craft_id: number = 0): Promise<void> {
      console.log('hoursReportStore.fetchReports()', user_ids, start_date, end_date)
      if (isPending.value) return
      
      isPending.value = true
      cacheBuster.value += 1
      
      itemId.value = 0
      itemBody.value = {
         user_list: user_ids,
         group_list: group_ids,
         start_date: start_date,
         end_date: end_date
      }

      if (craft_id > 0) {
         itemBody.value.craft_id = craft_id
      }
      
      await execFetchCustomReport()
      
      if (fetchCustomReportError.value) {
         console.log('hoursReportStore.fetchCustomReport().fetchCustomReportError:', fetchCustomReportError.value as ErrorT)   
         if (fetchCustomReportData.value) console.log(fetchCustomReportData.value)
            console.log('--',(fetchCustomReportError.value.message))
         if (fetchCustomReportError.value.message.includes('404 Not Found')) {
            setSnackbar({
               type: `warning`,
               text: `No reports could be found using the given criteria.`,
            })
         }
         else {
            showError(fetchCustomReportError.value as ErrorT)
         }
      }
      else if (fetchCustomReportData.value) {
         console.log('hoursReportStore.fetchCustomReport().fetchCustomReportData:', fetchCustomReportData.value)
         setSnackbar({
            type: `success`,
            text: `Custom report successfully generated!`,
         })
         FileSaver.saveAs(fetchCustomReportData.value as Blob, `Report_${dateString(new Date(Date.now()))}_${timeString(new Date(Date.now()))}.xlsx`)
      }

      isPending.value = false
   }

   /*********************************************
    * Set the extension_date field of a report. *
    *********************************************/

   const { data: updateExtensionDateData, error: updateExtensionDateError, execute: execUpdateExtensionDate } = useFetch(() => { return apiUrl.value }, {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsExtenstionDate,
      immediate: false,
      watch: false,
   })
   async function updateExtensionDate(report_id: number, extensionDate: Date): Promise<void> {
       console.log('hoursReportStore.updateExtensionDate()')
       if (isPending.value) return

       isPending.value = true
       cacheBuster.value += 1

       itemId.value = report_id
       paramsExtenstionDate.value.extension_date = `${dateString(extensionDate)} 00:00:00`
 
       await execUpdateExtensionDate()
 
       if (updateExtensionDateError.value) {
          console.log('hoursReportStore.updateExtensionDate().updateExtensionDateError:', updateExtensionDateError.value as ErrorT)   
          showError(updateExtensionDateError.value as ErrorT)
       }
       else if (updateExtensionDateData.value) {
          console.log('hoursReportStore.updateExtensionDate().updateExtensionDateData:', updateExtensionDateData.value)
          setSnackbar({
             type: `success`,
             text: `Extension date successfully updated!`,
          })
       }

       isPending.value = false
   }

   return {
      currentReportingPeriod,
      previousReportingPeriod,
      reports,
      report,
      total,
      isPending,
      combinedHours,
      updateCombinedHours,
      fetchReports,
      fetchCustomReport,
      isWithinPeriod,
      saveLogs,
      updateExtensionDate,
      $reset
   }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useHoursReportStore, import.meta.hot))
}
