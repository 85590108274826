import type { Frequency as T, ErrorT} from '~/types'

export const useFrequencyStore = defineStore('frequencyStore', () => {
    const config = useRuntimeConfig()
    const headers = computed(() => { return useAuthStore().headers })
    const frequencies = ref<Array<T>>([])
    const isPending = ref(false)

    function $reset(): void {
        frequencies.value = []
        isPending. value = false
    }

    const apiUrl = computed(() => `${config.public.API_URL}/api/frequency/` )

    const { data: fetchData, error: fetchError, execute: execFetch } = useFetch(() => { return apiUrl.value}, {
        method: 'GET',
        baseURL: config.public.API_URL,
        headers: headers,
        immediate: false,
        watch: false,
     })
    async function fetchAll(): Promise<void> {
        console.log('frequencyStore.fetchAll()')
        if (isPending.value) return

        isPending.value = true

        await execFetch()

        if (fetchError.value) {
            console.log('frequencyStore.fetchAll().fetchError:', fetchError.value as ErrorT)
            showError(fetchError.value as ErrorT)
        }
        else if (fetchData.value) {
            console.log('frequencyStore.fetchAll().fetchData:', fetchData.value)
            frequencies.value = fetchData.value.data as Array<T>
        }

        isPending.value = false
    }

    return {
        frequencies,
        fetchAll,
        $reset
    }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useFrequencyStore, import.meta.hot))
}