import { useAuthStore } from '~/stores/auth'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAPI } from '~/composables/useAPI'
import { useUserStore } from '~/stores/user'
import { penaltyTask } from '~/constants/app'
import type { Task as T, Craft, ErrorT } from '~/types'

export const useTaskStore = defineStore('taskStore', () => {
   const config = useRuntimeConfig()
   const headers = computed(() => { return useAuthStore().headers })
   const { setSnackbar } = useSnackbarStore()
   const { showError } = useAPI()
   const currCraft = ref<Craft>({} as Craft)
   const activeTasks = ref<Array<T>>([])
   const activeTotal = ref(0)
   const hiddenTasks = ref<Array<T>>([])
   const hiddenTotal = ref(0)
   const selectedTaskIds = ref<Array<number>>([])
   const isEditMode = ref<boolean>(false)
   const isPending = ref(false)
   const cacheBuster = ref(0)
   const parameters = ref({
      page: 1,
      perPage: 10,
      order_direction: 'asc',
      group_by: [],
      order_by: 'name',
      selected_locals: '',
      search: '',
      watch: [cacheBuster.value]
   })
   const parametersHidden = ref({
      page: 1,
      perPage: 10,
      order_direction: 'asc',
      group_by: [],
      order_by: 'name',
      selected_locals: '',
      search: '',
      watch: [cacheBuster.value]
   })
   const paramsShowAll = computed(() => {
      return { ...parameters.value, showall: true }
   })
   const paramsShowAllHidden = computed(() => {
      return { ...parametersHidden.value, showall: true, status: 0 }
   })
   const paramsShowSubHidden = computed(() => {
      return { ...parametersHidden.value, status: 0 }
   })

   const craftId = ref<number>(0)
   const itemId = ref<number>(0)
   const itemBody = ref({})

   function $reset(): void {
      activeTasks.value = []
      activeTotal.value = 0
      hiddenTasks.value = []
      hiddenTotal.value = 0
      selectedTaskIds.value = []
      isEditMode.value = false
      parameters.value.search = ''
      parameters.value.selected_locals = ''
      parametersHidden.value.search = ''
      parametersHidden.value.selected_locals = ''
      itemId.value = 0
      itemBody.value = {}
      isPending.value = false
   }

   const apiUrl = computed(() => `${config.public.API_URL}/api/tasks/${itemId.value === 0 ? '' : itemId.value}`)
   const apiUrl_post = computed(() => `${config.public.API_URL}/api/task_post`)
   const apiCraftsUrl = computed(() => `${config.public.API_URL}/api/crafts/${craftId.value === 0 ? '' : craftId.value}`)
   const apiSubscriptionUrl = computed(() => `${config.public.API_URL}/api/local/task_list`)
   const apiSwapTasksUrl = computed(() => `${config.public.API_URL}/api/local/swap_tasks`)
   const apiIncorporateUrl = computed(() => `${config.public.API_URL}/api/task/take_ownership/${itemId.value}`)
   
   const { data: fetchSubActiveTasksData, error: fetchSubActiveTasksError, execute: execFetchSubActiveTasks } = useFetch(() => { return apiCraftsUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: parameters,
      immediate: false,
      watch: false,
   })
   const { data: fetchAllActiveTasksData, error: fetchAllActiveTasksError, execute: execFetchAllActiveTasks } = useFetch(() => { return apiCraftsUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsShowAll,
      immediate: false,
      watch: false,
   })
   async function fetchActiveTasks(crafts: Array<Craft>, showAll: boolean = false, addGeneralTasks: boolean = false, isTaskManager: boolean = false): Promise<void> {
      console.log('taskStore.fetchActiveTasks()')
      if (isPending.value) return

      if (!crafts[0] || !crafts[0].id || crafts[0].id === 0) {
         currCraft.value = {} as Craft
         return
      }

      isPending.value = true

      if (!isTaskManager) {
         activeTasks.value = []
         activeTotal.value = 0
         selectedTaskIds.value = []
      }

      // Ignore repeated or missing secondary craft
      if (crafts.length > 1 && (!crafts[1] || !crafts[1].id || crafts[0].id === crafts[1].id)) crafts.splice(1, 1)

      for (let i = 0; i < crafts.length; i++) {
         if (!crafts[i] || !crafts[i].id) continue;

         currCraft.value = crafts[i]
         craftId.value = crafts[i].id

         cacheBuster.value += 1

         if (showAll) {
            await execFetchAllActiveTasks()

            if (fetchAllActiveTasksError.value) {
               console.log('taskStore.fetchActiveTasks().fetchAllActiveTasksError:', fetchAllActiveTasksError.value as ErrorT)
               showError(fetchAllActiveTasksError.value as ErrorT)
            }
            else if (fetchAllActiveTasksData.value) {
               console.log('taskStore.fetchActiveTasks().fetchAllActiveTasksData:', fetchAllActiveTasksData.value)

               const thisCraftsTasks = fetchAllActiveTasksData.value.tasks.data as Array<T>
               if (thisCraftsTasks.length > 0) {
                  thisCraftsTasks[0] = { ...thisCraftsTasks[0], craftName: crafts[i].name }
               }
               
               if (!isTaskManager) {
                  activeTasks.value = activeTasks.value.concat(fetchAllActiveTasksData.value.tasks.data as Array<T>)
                  activeTotal.value += fetchAllActiveTasksData.value.tasks.total
               }
               else {
                  activeTasks.value = fetchAllActiveTasksData.value.tasks.data as Array<T>
                  activeTotal.value = fetchAllActiveTasksData.value.tasks.total
               }
            }
         }
         else {
            await execFetchSubActiveTasks()

            if (fetchSubActiveTasksError.value) {
               console.log('taskStore.fetchActiveTasks().fetchSubActiveError:', fetchSubActiveTasksError.value as ErrorT)
               showError(fetchSubActiveTasksError.value as ErrorT)
            }
            else if (fetchSubActiveTasksData.value) {
               console.log('taskStore.fetchActiveTasks().fetchSubActiveData:', fetchSubActiveTasksData.value)
               selectedTaskIds.value = selectedTaskIds.value.concat((fetchSubActiveTasksData.value.tasks.data as Array<T>).map((s: T) => s.id))

               const thisCraftsTasks = fetchSubActiveTasksData.value.tasks.data as Array<T>
               if (thisCraftsTasks.length > 0) {
                  thisCraftsTasks[0] = { ...thisCraftsTasks[0], craftName: crafts[i].name }
               }

               if (!isTaskManager) {
                  activeTasks.value = activeTasks.value.concat(thisCraftsTasks)
                  activeTotal.value += fetchSubActiveTasksData.value.tasks.total
               }
               else {
                  activeTasks.value = fetchSubActiveTasksData.value.tasks.data as Array<T>
                  activeTotal.value = fetchSubActiveTasksData.value.tasks.total
               }
            }
         }
      }

      if (addGeneralTasks) {
         craftId.value = 1
         await execFetchSubActiveTasks()

         if (fetchSubActiveTasksError.value) {
            console.log('taskStore.fetchActiveTasks().fetchSubActiveError:', fetchSubActiveTasksError.value as ErrorT)
            showError(fetchSubActiveTasksError.value as ErrorT)
         }
         else if (fetchSubActiveTasksData.value) {
            console.log('taskStore.fetchActiveTasks().fetchSubActiveData:', fetchSubActiveTasksData.value)
            selectedTaskIds.value = selectedTaskIds.value.concat((fetchSubActiveTasksData.value.tasks.data as Array<T>).map((s: T) => s.id))

            const thisCraftsTasks = fetchSubActiveTasksData.value.tasks.data as Array<T>
            if (thisCraftsTasks.length > 0) {
               thisCraftsTasks[0] = { ...thisCraftsTasks[0], craftName: "General" }
            }

            activeTasks.value = activeTasks.value.concat(thisCraftsTasks)
            activeTotal.value += fetchSubActiveTasksData.value.tasks.total
         }
      }
      

      isPending.value = false
   }

   const { data: fetchSubHiddenData, error: fetchSubHiddenError, execute: execFetchSubHidden } = useFetch(() => { return apiCraftsUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsShowSubHidden,
      immediate: false,
      watch: false,
   })
   const { data: fetchAllHiddenData, error: fetchAllHiddenError, execute: execFetchAllHidden } = useFetch(() => { return apiCraftsUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsShowAllHidden,
      immediate: false,
      watch: false,
   })
   async function fetchHiddenTasks(craft: Craft, showAll: boolean = false): Promise<void> {
      console.log('taskStore.fetchHiddenTasks()')
      if (isPending.value) return

      if (craft.id === undefined || craft.id === 0) {
         currCraft.value = {} as Craft
         //selectedTaskIds.value = []
         hiddenTasks.value = []
         hiddenTotal.value = 0
         return
      }

      isPending.value = true
      cacheBuster.value += 1

      currCraft.value = craft
      craftId.value = craft.id || 0

      if (showAll) {
         await execFetchAllHidden()

         if (fetchAllHiddenError.value) {
            console.log('taskStore.fetchHiddenTasks().fetchAllHiddenError:', fetchAllHiddenError.value as ErrorT)
            showError(fetchAllHiddenError.value as ErrorT)
         } else if (fetchAllHiddenData.value) {
            console.log('taskStore.fetchHiddenTasks().fetchAllHiddenData:', fetchAllHiddenData.value)
            if (fetchAllHiddenData.value.tasks) {
               hiddenTasks.value = fetchAllHiddenData.value.tasks.data as Array<T>
               hiddenTotal.value = fetchAllHiddenData.value.tasks.total
            }
         }
      }
      else {
         await execFetchSubHidden()

         if (fetchSubHiddenError.value) {
            console.log('taskStore.fetchHiddenTasks().fetchSubHiddenError:', fetchSubHiddenError.value as ErrorT)
            showError(fetchSubHiddenError.value as ErrorT)
         }
         else if (fetchSubHiddenData.value) {
            console.log('taskStore.fetchHiddenTasks().fetchSubHiddenData:', fetchSubHiddenData.value)
            if (fetchSubHiddenData.value.tasks) {
               hiddenTasks.value = fetchSubHiddenData.value.tasks.data as Array<T>
               hiddenTotal.value = fetchSubHiddenData.value.tasks.total
            }
         }
      }

      const found = hiddenTasks.value.find(ht => ht.id === penaltyTask.id)
      if (found) {
         const index = hiddenTasks.value.indexOf(found)
         hiddenTasks.value.splice(index, 1)
         hiddenTotal.value -= 1
      }

      isPending.value = false
   }

   const swapBody = ref({
      old_id: 0,
      new_id: 0
   })
   const { data: swapTasksData, error: swapTasksError, execute: execSwapTasks } = useFetch (() => { return apiSwapTasksUrl.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: swapBody,
      immediate: false,
      watch: false,
   })
   async function swapTasks(old_id: number, new_id: number): Promise<void> {
      console.log('taskStore.swapTasks()')
      if (isPending.value) return

      isPending.value = true
      cacheBuster.value += 1

      swapBody.value = {
         old_id: old_id,
         new_id: new_id
      }

      await execSwapTasks()

      if (swapTasksError.value) {
         console.log('taskStore.swapTasks().swapTasksError:', swapTasksError.value as ErrorT)
         setSnackbar({
            type: 'error',
            text: `Could not update Craft List.`
         })
         showError(swapTasksError.value as ErrorT)
      }
      else if (swapTasksData.value) {
         console.log('taskStore.swapTasks().swapTasksData:', swapTasksData.value)

         const found = selectedTaskIds.value.find(id => id === old_id)
         if (found) {
            const index = selectedTaskIds.value.indexOf(found)
            selectedTaskIds.value[index] = new_id
         }
      }

      isPending.value = false
   }

   const { data: setSubscriptionsData, error: setSubscriptionsError, execute: execSetSubscriptions } = useFetch(() => { return apiSubscriptionUrl.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: itemBody,
      immediate: false,
      watch: false,
   })
   async function setSubscriptions(): Promise<void> {
      console.log('taskStore.setSubscriptions()')

      isPending.value = true
      cacheBuster.value += 1

      itemBody.value = {
         task_list: selectedTaskIds.value,
         craft_id: currCraft.value,
         local_id: useUserStore().current.local.id
      }

      await execSetSubscriptions()

      if (setSubscriptionsError.value) {
         console.log('taskStore.setSubscriptions().setSubscriptionsError:', setSubscriptionsError.value as ErrorT)
         setSnackbar({
            type: 'error',
            text: `Could not update Task List.`
         })
         showError(setSubscriptionsError.value as ErrorT)
      }
      else if (setSubscriptionsData.value) {
         console.log('taskStore.setSubscriptions().setSubscriptionsData:', setSubscriptionsData.value)
         setSnackbar({
            type: 'success',
            text: `Task List successfully updated!`
         })
      }

      isPending.value = false
   }

   const { data: createData, error: createError, execute: execCreate } = useFetch(() => { return apiUrl_post.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: itemBody,
      immediate: false,
      watch: false,
   })
   async function createTask(name: string, craft_id: number, local_id: number = 0): Promise<T> {
      console.log('taskStore.createTask()', name, craft_id)
      if (isPending.value) return {} as T

      isPending.value = true
      cacheBuster.value += 1

      let success = {} as T
      itemId.value = 0
      if (local_id > 0) {
         itemBody.value = {
            name: name,
            craft_id: craft_id,
            non_ojt: craft_id === 1 ? 1 : 0,
            local_id: local_id
         }
      }
      else {
         itemBody.value = {
            name: name,
            craft_id: craft_id,
            non_ojt: craft_id === 1 ? 1 : 0
         }
      }

      await execCreate()

      if (createError.value) {
         console.log('taskStore.createTask().createError:', createError.value as ErrorT)
         if (createError.value.data.message === 'Duplicate Name')
            setSnackbar({
               type: `error`,
               text: `"${name}" already exists. Task may be hidden.`,
            })
         else
            showError(createError.value as ErrorT)
      }
      else if (createData.value) {
         console.log('taskStore.createTask().createData:', createData.value)
         setSnackbar({
            type: `success`,
            text: `"${name}" successfully created!`,
         })
         success = createData.value as T
      }

      isPending.value = false
      return success
   }

   const { data: deleteData, error: deleteError, execute: execDelete } = useFetch(() => { return apiUrl.value }, {
      method: 'DELETE',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })
   async function hideTask(task: T): Promise<boolean> {
      console.log('taskStore.deleteRow()')
      if (isPending.value) return false

      isPending.value = true
      cacheBuster.value += 1

      let success = false
      itemId.value = task.id

      await execDelete()

      if (deleteError.value) {
         console.log('taskStore.deleteRow().deleteError:', deleteError.value as ErrorT)
         showError(deleteError.value as ErrorT)
      }
      else if (deleteData.value) {
         console.log('taskStore.deleteRow().deleteData:', deleteData.value)
         success = true
      }

      isPending.value = false
      return success
   }

   const restoreObj = ref({})
   const restoreBody = computed(() => restoreObj.value)
   const restoreURL = computed(() => `${config.public.API_URL}/api/tasks/bulk_restore`)
   const { data: restoreData, error: restoreError, execute: execRestore } = useFetch(() => { return restoreURL.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: restoreBody,
      immediate: false,
      watch: false,
   })
   async function restoreTasks(ids: Array<number>): Promise<void> {
      console.log('taskStore.restoreTasks()', ids)
      if (isPending.value) return

      isPending.value = true
      restoreObj.value = {
         task_list: ids,
      }

      await execRestore()

      if (restoreError.value) {
         console.log('taskStore.restoreTasks().restoreError:', restoreError.value as ErrorT)
         showError(restoreError.value as ErrorT)
      } else if (restoreData.value) {
         console.log('taskStore.restoreTasks().restoreData:', restoreData.value)
         setSnackbar({
            type: `success`,
            text: 'Task(s) successfully restored.',
         })
      }

      isPending.value = false
   }

   return {
      parameters,
      parametersHidden,
      isPending,
      activeTasks,
      activeTotal,
      hiddenTasks,
      hiddenTotal,
      selectedTaskIds,
      currCraft,
      isEditMode,
      fetchActiveTasks,
      fetchHiddenTasks,
      createTask,
      hideTask,
      restoreTasks,
      setSubscriptions,
      swapTasks,
      $reset
   }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useTaskStore, import.meta.hot))
}