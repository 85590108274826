import { useAuthStore } from '~/stores/auth'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAPI } from '~/composables/useAPI'
import type { Group as T, ErrorT } from '~/types'

export const useGroupStore = defineStore('groupStore', () => {
    const config = useRuntimeConfig()
    const headers = computed(() => { return useAuthStore().headers })
    const { setSnackbar } = useSnackbarStore()
    const { showError } = useAPI()
    const groups = ref<T[]>([])
    const isPending = ref(false)
    const total = ref(0)
    const cacheBuster = ref(0)
    const parameters = ref({
        page: 1,
        perPage: 10,
        order_direction: 'asc',
        group_by: [],
        order_by: 'name',
        search: '',
        selected_locals: '',
        watch: [cacheBuster.value]
    })
    const selectedGroupIds = ref<Array<number>>([])

    function $reset(): void {
        groups.value = []
        total.value = 0
        parameters.value.search = ''
        parameters.value.selected_locals = ''
        isPending.value = false
    }

    const itemId = ref<number>(0)
    const itemBody = ref({})
    const membersBody = ref({ user_list: [] as Array<number> })
    const apiUrl = computed(() => `${config.public.API_URL}/api/usergroups/${itemId.value === 0 ? '' : itemId.value}` )
    const apiUrl_post = computed(() => `${config.public.API_URL}/api/usergroups` )
    const apiMembersUrl = computed(() => `${config.public.API_URL}/api/usergroup/user_list/${itemId.value === 0 ? '' : itemId.value}` )

    const { data: fetchAllData, error: fetchAllError, execute: execFetchAll } = useFetch(() => { return apiUrl.value}, {
        method: 'GET',
        baseURL: config.public.API_URL,
        headers: headers,
        params: parameters,
        immediate: false,
        watch: false,
    })
    async function fetchAll(): Promise<void> {
        console.log('groupStore.fetchAll()')

        isPending.value = true
        cacheBuster.value += 1

        itemId.value = 0
  
        await execFetchAll()
  
        if (fetchAllError.value) {
            console.log('groupStore.fetchAll().fetchAllError:', fetchAllError.value as ErrorT)  
            showError(fetchAllError.value as ErrorT)         
        }
        else if (fetchAllData.value) {
            console.log('groupStore.fetchAll().fetchAllData:', fetchAllData.value)
            groups.value = fetchAllData.value.data as Array<T>
            total.value = fetchAllData.value.total
        }

        isPending.value = false
    }

    const { data: fetchRowsData, error: fetchRowsError, execute: execFetchRows } = useFetch(() => { return apiUrl.value}, {
        method: 'GET',
        baseURL: config.public.API_URL,
        headers: headers,
        params: parameters,
        immediate: false,
        watch: false,
    })
    async function fetchRows(): Promise<boolean> {
        console.log('groupStore.fetchRows()')
        if (isPending.value) return false

        isPending.value = true
        cacheBuster.value += 1

        let success = false
        itemId.value = 0
  
        await execFetchRows()
  
        if (fetchRowsError.value) {
           console.log('groupStore.fetchRows().fetchRowsError:', fetchRowsError.value as ErrorT)
           showError(fetchRowsError.value as ErrorT)
        }
        else if (fetchRowsData.value) {
           console.log('groupStore.fetchRows().fetchRowsData:', fetchRowsData.value)
           groups.value = fetchRowsData.value.data as Array<T>
           groups.value = groups.value.map(g => { return { ...g, showAll: false } })
           total.value = fetchRowsData.value.total
           success = true
        }

        isPending.value = false
        return success
    }

    const { data: createData, error: createError, execute: execCreate} = useFetch(() => { return apiUrl_post.value}, {
        method: 'POST',
        baseURL: config.public.API_URL,
        headers: headers,
        body: itemBody,
        immediate: false,
        watch: false,
    })
    async function createRow(name: string, local_id: number): Promise<T> {
        console.log('groupStore.createRow()')
        let tempGroup = {} as T
        if (isPending.value) return tempGroup

        isPending.value = true
        cacheBuster.value += 1

        itemId.value = 0
        itemBody.value = { local_id: local_id, name: name }
        
        await execCreate()

        if (createError.value) {
            console.log('groupStore.createRow().createError:', createError.value as ErrorT)
            if (createError.value.data.message == 'Duplicate Name')
               setSnackbar({
                  type: 'error',
                  text: `The group name "${name}" is already in use.`
               })
            else
               showError(createError.value as ErrorT)
        }
        else if (createData.value){
            console.log('groupStore.createRow().createData:', createData.value)
            setSnackbar({
               type: `success`,
               text: `New group successfully created!`,
            })
            tempGroup = createData.value as T
        }
        
        isPending.value = false
        return tempGroup
    }

    const { data: updateData, error: updateError, execute: execUpdate } = useFetch(() => { return apiUrl.value}, {
        method: 'PUT',
        baseURL: config.public.API_URL,
        headers: headers,
        body: itemBody,
        immediate: false,
        watch: false,
    })
    async function updateRow(group: T): Promise<boolean> {
        console.log('groupStore.updateRow()')
        if (isPending.value) return false

        isPending.value = true
        cacheBuster.value += 1

        let success = false
        itemId.value = group.id
        itemBody.value = { name: group.name }

        await execUpdate()

        if (updateError.value) {
            console.log('groupStore.updateRow().updateError:', updateError.value as ErrorT)
            if (updateError.value.data.message == 'Duplicate Name')
               setSnackbar({
                  type: 'error',
                  text: `The group name "${group.name}" is already in use.`
               })
            else
               showError(createError.value as ErrorT)
        }
        else if (updateData.value){
            console.log('groupStore.updateRow().updateData:', updateData.value)
            setSnackbar({
               type: `success`,
               text: `Group updated successfully!`,
            })
            success = true
        }
        
        isPending.value = false
        return success
    }

    const { data: updateMembersData, error: updateMembersError, execute: execUpdateMembers} = useFetch(() => { return apiMembersUrl.value}, {
        method: 'POST',
        baseURL: config.public.API_URL,
        headers: headers,
        body: membersBody,
        immediate: false,
        watch: false,
    })
    async function updateMembers(group_id: number, memberIds: Array<number>): Promise<boolean> {
        console.log('groupStore.updateMembers()', group_id, memberIds)
        if (isPending.value) return false

        isPending.value = true
        cacheBuster.value += 1

        let success = false
        itemId.value = group_id
        membersBody.value = { user_list: memberIds }

        await execUpdateMembers()

        if (updateMembersError.value) {
            console.log('groupStore.updateMembers().updateMembersError:', updateMembersError.value as ErrorT)
            setSnackbar({
               type: 'error',
               text: `Could not update members list.`
            })
            showError(updateMembersError.value as ErrorT)
        }
        else if (updateMembersData.value){
            console.log('groupStore.updateMembers().updateMembersData:', updateMembersData.value)
            setSnackbar({
               type: 'success',
               text: `Memebers list successfully updated!`
            })
            success = true
        }

        isPending.value = false
        return success
    }

    const { data: deleteData, error: deleteError, execute: execDelete } = useFetch(() => { return apiUrl.value}, {
        method: 'DELETE',
        baseURL: config.public.API_URL,
        headers: headers,
        immediate: false,
        watch: false,
    })
    async function deleteRow(id: number): Promise<boolean> {
        console.log('groupStore.deleteRow()')
        if (isPending.value) return false

        isPending.value = true
        cacheBuster.value += 1

        let success = false
        itemId.value = id

        await execDelete()

        if (deleteError.value) {
            console.log('groupStore.deleteRow().deleteError:', deleteError.value as ErrorT)
            showError(deleteError.value as ErrorT)
        }
        else if (deleteData.value) {
            console.log('groupStore.deleteRow().deleteData:', deleteData.value)
            setSnackbar({
               type: `success`,
               text: `Group has been removed.`,
            })
            success = true
        }
        
        isPending.value = false
        return success
    }

    return {
        groups,
        parameters,
        total,
        isPending,
        selectedGroupIds,
        fetchAll,
        fetchRows,
        createRow,
        updateRow,
        updateMembers,
        deleteRow,
        $reset
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useGroupStore, import.meta.hot))
}