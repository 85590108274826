import { useAuthStore } from '~/stores/auth'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAPI } from '~/composables/useAPI'
import type { Local as T, ErrorT } from '~/types'

export const useLocalStore = defineStore('localStore', () => {
   const config = useRuntimeConfig()
   const headers = computed(() => { return useAuthStore().headers })
   const { setSnackbar } = useSnackbarStore()
   const { showError } = useAPI()
   const locals = ref<T[]>([])
   const hiddenLocals = ref<T[]>([])
   const local = ref<T>({} as T)
   const tempLocal = ref<T>({} as T)
   const current = ref<T>({} as T)
   const total = ref(0)
   const hiddenTotal = ref(0)
   const isPending = ref(false)
   const cacheBuster = ref(0)
   const parameters = ref({
      page: 1,
      per_page: 10,
      order_direction: 'asc',
      groupBy: [],
      order_by: 'local_number',
      search: '',
      selected_states: '',
      status: 1,
      watch: [cacheBuster.value]
   })

   function $reset(): void {
      locals.value = []
      total.value = 0
      hiddenLocals.value = []
      hiddenTotal.value = 0
      local.value = {} as T
      tempLocal.value = {} as T
      current.value = {} as T
      parameters.value.search = ''
      parameters.value.selected_states = '',
      parameters.value.status = 1
      isPending.value = false
   }

   const getById = computed(() => {
      return (id: number) => locals.value.find((row) => row.id === id)
   })

   const getByName = computed(() => {
      return (name: string) => locals.value.find((row) => row.name === name)
   })

   const { data: localsData, error: localsError, execute: execLocals } = useFetch(`${config.public.API_URL}/api/locals`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: parameters,
      immediate: false,
      watch: false,
   })
   async function fetchLocals(getAll: boolean = false) {
      console.log(`localStore.fetchLocals() — isPending:`, isPending.value, getAll, parameters.value.per_page)
      if (isPending.value) return

      isPending.value = true

      cacheBuster.value += 1
      parameters.value.status = 1

      const currPerPage = parameters.value.per_page
      if (getAll) {
         parameters.value.per_page = Number.MAX_SAFE_INTEGER
      }

      await execLocals()

      if (localsError.value) {
         console.log('localStore.fetchLocals().localsError', localsError.value)
         showError(localsError.value as ErrorT)
      } else if (localsData.value) {
         console.log('localStore.fetchLocals().localsData', localsData.value)
         locals.value = localsData.value.data
         total.value = localsData.value.total
      }
      
      parameters.value.per_page = currPerPage
      isPending.value = false
   }

   const { data: hiddenLocalsData, error: hiddenLocalsError, execute: execHiddenLocals } = useFetch(`${config.public.API_URL}/api/locals`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: parameters,
      immediate: false,
      watch: false,
   })
   async function fetchHiddenLocals(): Promise<void> {
      console.log(`localStore.fetchHiddenLocals()`)
      if (isPending.value) return

      isPending.value = true

      cacheBuster.value += 1
      parameters.value.status = 0
      await execHiddenLocals()

      if (hiddenLocalsError.value) {
         console.log('localStore.fetchHiddenLocals().hiddenLocalsError', hiddenLocalsError.value)
         showError(hiddenLocalsError.value as ErrorT)
      } else if (hiddenLocalsData.value) {
         console.log('localStore.fetchHiddenLocals().hiddenLocalsData', hiddenLocalsData.value)
         hiddenLocals.value = hiddenLocalsData.value.data
         hiddenTotal.value = hiddenLocalsData.value.total
      }

      isPending.value = false
   }

   /**
    * Create a new local.
    */
   const { data: createData, error: createError, execute: execCreate } = useFetch(`${config.public.API_URL}/api/locals`, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: local,
      immediate: false,
      watch: false,
   })

   async function createLocal(): Promise<boolean> {
      if (isPending.value) return false

      if (!local.value) return false
      local.value = { ...local.value, evaluation: local.value.evaluation ? 1 : 0, self_evaluation: local.value.self_evaluation ? 1 : 0 }
      
      isPending.value = true
      let success = true
      cacheBuster.value += 1
      await execCreate()

      if (createError.value) {
         success = false
         if (createError.value.data.message === 'Duplicate Number') {
            setSnackbar({
               type: `error`,
               text: `The local number ${local.value.local_number} is already in use.`,
            })
         } else if (createError.value.data.message === 'Duplicate Address') {
            setSnackbar({
               type: `error`,
               text: `This address is already in use.`,
            })
         } else {
            showError(createError.value as ErrorT)
         }
      }
      else if (createData.value) {
         setSnackbar({
            type: `success`,
            text: `New local successfully created!`,
         })
      }

      isPending.value = false
      return success
   }

   /**
    * Update a local.
    */
   const localURL = computed(() => `${config.public.API_URL}/api/locals/${local.value ? local.value.id : 0}`)

   const { data: updateData, error: updateError, execute: execUpdate } = useFetch(() => { return localURL.value }, {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      body: local,
      immediate: false,
      watch: false,
   })

   async function updateLocal(): Promise<boolean> {
      console.log(`\n updateLocal(): local = ${JSON.stringify(local.value)}, headers = ${headers.value}`)
      if (isPending.value) return false
      
      if (!local.value) return false
      local.value = { ...local.value, evaluation: local.value.evaluation ? 1 : 0, self_evaluation: local.value.self_evaluation ? 1 : 0, allow_eval_review: local.value.allow_eval_review ? 1 : 0 }

      isPending.value = true
      let success = true

      cacheBuster.value += 1
      await execUpdate()

      if (updateError.value) {
         success = false
         if (updateError.value.data.message === 'Duplicate Number') {
            setSnackbar({
               type: `error`,
               text: `The local number ${local.value.local_number} is already in use.`,
            })
         } else if (updateError.value.data.message === 'Duplicate Address') {
            setSnackbar({
               type: `error`,
               text: `This address is already in use.`,
            })
         } else {
            showError(createError.value as ErrorT)
         }
      } else if (updateData.value) {
         setSnackbar({
            type: `success`,
            text: `Training center successfully updated!`,
         })
      }

      isPending.value = false
      return success
   }

   const localID = ref(0)
   const hideURL = computed(() => `${config.public.API_URL}/api/locals/${localID.value ? localID.value : 0}`)
   const { data: hideData, error: hideError, execute: execHideLocal } = useFetch(() => { return hideURL.value }, {
      method: 'DELETE',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })
   async function hideLocal(id: number): Promise<void> {
      isPending.value = true
      localID.value = id
      console.log(`\n hideLocal(${id})`)

      cacheBuster.value += 1
      await execHideLocal()

      if (hideError.value) {
         showError(hideError.value as ErrorT)
         isPending.value = false
      } else if (hideData.value) {
         setSnackbar({
            type: `success`,
            text: `Training center has been hidden.`,
         })
         isPending.value = false
      }
   }

   const restoreObj = ref({})
   const restoreBody = computed(() => restoreObj.value)

   const restoreURL = computed(() => `${config.public.API_URL}/api/locals/bulk_restore`)
   const { data: restoreData, error: restoreError, execute: execRestore } = useFetch(() => { return restoreURL.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: restoreBody,
      immediate: false,
      watch: false,
   })

   async function restoreLocals(ids: Array<number>) {
      console.log('localStore.restoreLocals()')
      if (isPending.value) return

      isPending.value = true
      restoreObj.value = {
         local_list: ids,
      }

      cacheBuster.value += 1
      await execRestore()

      if (restoreError.value) {
         console.log('localStore.restoreLocals().restoreError:', restoreError.value as ErrorT)
         showError(restoreError.value as ErrorT)
      } else if (restoreData.value) {
         console.log('localStore.restoreLocals().restoreData:', restoreData.value)
         setSnackbar({
            type: `success`,
            text: 'Local(s) successfully restored.',
         })
      }
      isPending.value = false
   }

   return {
      locals, hiddenLocals, local, tempLocal, localID, current, getById, getByName, parameters, total, hiddenTotal, isPending, 
      fetchLocals, fetchHiddenLocals, createLocal, updateLocal, hideLocal, restoreLocals, $reset
   }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useLocalStore, import.meta.hot))
}
