import { storeToRefs } from 'pinia'
import { useCraftStore } from '~/stores/craft'
import { useEvaluationStore } from '~/stores/evaluation'
import { useGroupStore } from '~/stores/group'
import { useHoursReportStore } from '~/stores/hoursReport'
import { useLocalStore } from '~/stores/local'
import { usePageStore } from '~/stores/page'
import { useNotificationStore } from '~/stores/notification'
import { useTaskStore } from '~/stores/task'
import { useUserStore } from '~/stores/user'
import { useSnackbarStore } from '~/stores/snackbar'
import type { ErrorT } from '~/types'

export const useAuthStore = defineStore('authStore', () => {
   const isAuthenticated = ref<boolean>(false)
   const config = useRuntimeConfig()
   const headers = ref({})
   const { setSnackbar } = useSnackbarStore()
   const { current } = storeToRefs(useUserStore())
   const isRedirect = ref(false)
   const reloadPage = ref(false)
   const isPending = ref(false)
   const lastRoute = ref('')
   const isLoggedIn = ref(false)
   const isPasswordSet = ref(false)
   const loginForm = ref({})
   const emailForm = ref({ email: '' })
   const cacheBuster = ref(0)

   const parameters = ref({
      watch: [cacheBuster.value]
   })

   function $reset(): void {
      isAuthenticated.value = false
      isRedirect.value = false
      reloadPage.value = false
      lastRoute.value = ''
      isLoggedIn.value = false
      loginForm.value = {}
      emailForm.value = { email: '' }
      isPending.value = false
      cacheBuster.value = 0
   }

   const loginPost = computed(() => {
      return loginForm.value
   })

   const emailPost = computed(() => {
      return emailForm.value
   })

   function setAuth(authenticated: boolean): void {
      isAuthenticated.value = authenticated
   }

   const loginAsId = ref<number>(0);

   const loginUrl = computed(() => `${config.public.API_URL}/login_post`)
   const loginAsUrl = computed(() => `${config.public.API_URL}/api/loginas/${loginAsId.value}`)

   const h = computed(() => headers.value)

   const { data: loginData, error: loginError, execute: execLogin } = useFetch(() => { return loginUrl.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: h,
      body: loginPost,
      immediate: false,
      watch: false,
   })
   async function loginUser(_loginForm: any): Promise<string> {
      console.log('authStore.loginUser()', isPending.value)
      if (isPending.value) return 'Error: Please try again.'

      resetStores()
      await fetchToken()

      isPending.value = true
      cacheBuster.value += 1
      let msg = ''

      loginForm.value = _loginForm

      await execLogin()

      if (loginError.value) {
         if (loginError.value.message.includes('disabled')) {
            msg = `Your account is disabled. Please contact your local administrator.`
         }
         else if (loginError.value.message.includes('user not found')) {
            msg = `The username ${loginForm.value.username} could not be found.`
         }
         else if (!!loginError.value.message) {
            console.log('authStore.loginUser().loginError:', loginError.value as ErrorT)
            msg = 'Invalid Credentials'
         }
         isLoggedIn.value = false
      } else {
         console.log('authStore.loginUser().loginData:', loginData.value)
         await fetchToken()
         isLoggedIn.value = true
         setAuth(true)
      }

      loginForm.value = {}
      isPending.value = false
      return msg
   }

   const { data: loginAsData, error: loginAsError, execute: execAsLogin } = useFetch(() => { return loginAsUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })
   async function loginAsUser(id: number): Promise<boolean> {
      console.log('authStore.loginAsUser()', loginPost.value, isPending.value)
      if (isPending.value) return false

      isPending.value = true
      cacheBuster.value += 1

      loginAsId.value = id;

      await execAsLogin()

      if (loginAsError.value) {
         console.log('authStore.loginAsUser().loginAsError:', loginAsError.value as ErrorT)
         showError(loginAsError.value)
      } else if (loginAsData.value) {
         setSnackbar({
            type: `success`,
            text: `Successfully logged in another account.`,
         })
      }

      isPending.value = false
      return isLoggedIn.value
   }

   const isAdmin = computed(() => {
      return isAuthenticated.value && (current.value.role_id === 1 || current.value.role_id === 2)
   })

   const { data: tokenData, error: tokenError, execute: execToken } = useFetch(`${config.public.API_URL}/csrf`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: parameters,
      immediate: false,
      watch: false,
   })
   async function fetchToken(): Promise<void> {
      console.log(`authStore.fetchToken()`)
      
      cacheBuster.value += 1
      await execToken()

      if (tokenError.value) {
         console.log('authStore.fetchToken().tokenError:', tokenError.value)
      } else if (tokenData.value) {
         console.log('authStore.fetchToken().tokenData:', tokenData.value as string)
         headers.value = {
            'X-CSRF-TOKEN': tokenData.value as string
         }
      }
   }

   async function logout(): Promise<void> {
      console.log('authStore.logout()')
      if (isPending.value) return

      isPending.value = true
      cacheBuster.value += 1

      try {
         await $fetch(`${config.public.API_URL}/logout`, {
            method: 'GET',
            params: parameters,
            baseURL: config.public.API_URL,
         })

         resetStores()
         $reset()

         navigateTo({ path: `/login` })
      } catch (ex) {
         console.log(`logout ERROR!`, ex)
      }

      isPending.value = false
   }

   function resetStores(): void {
      console.log('authStore.resetStores()')
      useAppStore().$reset()
      useCraftStore().$reset()
      useEvaluationStore().$reset()
      useGroupStore().$reset()
      useHoursReportStore().$reset()
      useLocalStore().$reset()
      useNotificationStore().$reset()
      usePageStore().$reset()
      useTaskStore().$reset()
      useUserStore().$reset()
   }

   const { data: resetData, error: resetError, execute: execSendResetEmail } = useFetch(`${config.public.API_URL}/forgotpassword`, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: emailPost,
      immediate: false,
      watch: false,
   })
   async function sendResetEmail(email: string): Promise<boolean> {
      console.log(`authStore.sendResetEmail(${email})`)
      if (isPending.value) return false

      isPending.value = true
      cacheBuster.value += 1
      let success = false

      emailForm.value.email = email

      await fetchToken()
      console.log(`authStore.sendResetEmail() a`)
      await execSendResetEmail()
      console.log(`authStore.sendResetEmail() b`)

      if (resetError.value) {
         console.log('authStore.sendResetEmail().resetError', resetError.value)
         if (resetError.value.data) {
            setSnackbar({
               type: `error`,
               text: `No accout for "${email}" could be found.`,
            })
         }
         else {
            setSnackbar({
               type: `error`,
               text: `Server communication error. Please try again.`,
            })
         }
      }
      else if (resetData.value) {
         console.log('authStore.sendResetEmail().resetData', resetData.value)
         setSnackbar({
            type: `success`,
            text: 'Reset E-mail sent.',
         })
         success = true
      }
      isPending.value = false
      return success
   }

   const passwordForm = ref({
      email: '',
      code: '',
      repassword: '',
      password: '',
   })
   const passwordPost = computed(() => {
      return passwordForm.value
   })
   const { data: newPasswordData, error: newPasswordError, execute: execNewPassword } = useFetch(`${config.public.API_URL}/new_password`, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: passwordPost,
      watch: false,
      immediate: false
   })
   async function setNewPassword(password: string, repassword: string, email: string, code: string): Promise<boolean> {
      console.log(`authStore.setNewPassword()`)
      if (isPending.value) return false
      
      isPending.value = true

      await fetchToken()

      cacheBuster.value += 1

      passwordForm.value = {
         email: email,
         code: code,
         repassword: repassword,
         password: password,
      }

      await execNewPassword()

      if (newPasswordError.value) {
         console.log('authStore.setNewPassword().newPasswordError:', newPasswordError.value)
         if (newPasswordError.value.statusCode === 403) {
            isRedirect.value = true
            await logout()
         }
         else if (newPasswordError.value.data) {
            setSnackbar({
               type: `error`,
               text: newPasswordError.value.data.message,
            })
         }
         else {
            setSnackbar({
               type: `error`,
               text: `Server communication error. Please try again.`,
            })
         }
         isPasswordSet.value = false
      }
      else if (newPasswordData.value) {
         setSnackbar({
            type: `success`,
            text: 'New password set.',
         })
         console.log(`New password set!`)
         isPasswordSet.value = true
      }

      isPending.value = false
      return isPasswordSet.value
   }

   return {
      isAdmin, headers, isAuthenticated, lastRoute, loginForm, isLoggedIn, isPending, isRedirect, reloadPage,
      loginUser, loginAsUser, logout, fetchToken, sendResetEmail, resetStores, $reset, setNewPassword
   }
},
   {
      persist: {
         storage: localStorage
      }
   }
)

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
