export const getEnumKey = (obj, value) => {
  return Object.entries(obj).find(([key, val]) => val === value)?.[0]
}

export const isObject = function (object) {
  return object != null && typeof object === 'object'
}

export const deepEqual = function (object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false
    }
  }

  return true
}

export const intToRoman = (num) => {
  const map = {
    M:  1000,
    CM: 900,
    D:  500,
    CD: 400,
    C:  100,
    XC: 90,
    L:  50,
    XL: 40,
    X:  10,
    IX: 9,
    V:  5,
    IV: 4,
    I:  1
  }
  let result = ''

  for (key in map) {
    const repeatCounter = Math.floor(num / map[key])

    if (repeatCounter !== 0) {
      result += key.repeat(repeatCounter)
    }

    num %= map[key]

    if (num === 0) return result
  }

  return result
}

export const removeItemWithId = function (arr, id) {
  const objWithIdIndex = arr.findIndex((itemID) => itemID === id)

  if (objWithIdIndex > -1) {
    arr.splice(objWithIdIndex, 1)
  }

  return arr
}

export const dayInMs = 86400000

export const normalDate = function (ms) {
  const tempDate = useDateFormat(ms, 'MMM. DD, YYYY')
  return tempDate.value.toString().replace(/^"/, '').replace(/"$/, '').replace(/ 0/g, ' ').replace('May.', 'May')
}

export const leadingZero = function (num) {
  let str = num.toString()
  while (str.length < 2) str = '0' + str
  return str
}

export const dateString = function (date) {
  return `${date.getFullYear()}-${leadingZero(date.getMonth() + 1)}-${leadingZero(date.getDate())}`
}

export const timeString = function (date) {
  return `${leadingZero(date.getHours())}-${leadingZero(date.getMinutes())}-${leadingZero(date.getSeconds())}`
}

export const toTitle = function (str) {
  return str.replace(
    /{[^0-9][a-zA-Z]+[^a-zA-Z]*/g,
    (match) => {
      return match.charAt(0).toUpperCase() + match.substr(1).toLowerCase();
    }
  )
}

export const numbersOnly = function (evt) {
  if (evt.ctrlKey) return
  if (evt.key.length > 1) return
  if (/[0-9.]/.test(evt.key)) return
  evt.preventDefault()
}

export const formatAsPhone = function (evt) {
  const digits = evt.target.value.replace(/\D/g, '').substring(0, 10)
  const areaCode = digits.substring(0, 3)
  const prefix = digits.substring(3, 6)
  const suffix = digits.substring(6, 10)

  if(digits.length > 6) evt.target.value = `(${areaCode}) ${prefix}—${suffix}`
  else if(digits.length > 3) evt.target.value = `(${areaCode}) ${prefix}`
  else if(digits.length > 0) evt.target.value = `(${areaCode}`
}

export const formatAsPhoneWithReturn = function (str) {
  if (!str) return ''
  
  const digits = str.replace(/\D/g, '').substring(0, 10)
  const areaCode = digits.substring(0, 3)
  const prefix = digits.substring(3, 6)
  const suffix = digits.substring(6, 10)

  if(digits.length > 6) str = `(${areaCode}) ${prefix}—${suffix}`
  else if(digits.length > 3) str = `(${areaCode}) ${prefix}`
  else if(digits.length > 0) str = `(${areaCode}`

  return str
}