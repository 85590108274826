import { useAuthStore } from '~/stores/auth'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAPI } from '~/composables/useAPI'
import { useUserStore } from '~/stores/user'
import type { Craft as T, ErrorT } from '~/types'

export const useCraftStore = defineStore('craftStore', () => {
   const config = useRuntimeConfig()
   const headers = computed(() => { return useAuthStore().headers })
   const { setSnackbar } = useSnackbarStore()
   const { showError } = useAPI()
   const activeCrafts = ref<Array<T>>([])
   const activeTotal = ref(0)
   const hiddenCrafts = ref<Array<T>>([])
   const hiddenTotal = ref(0)
   const selectedCraftIds = ref<Array<number>>([])
   const isEditMode = ref<boolean>(false)
   const isPending = ref(false)
   const cacheBuster = ref(0)

   watch(selectedCraftIds, () => {
      /* Always select "General" */
      if (!selectedCraftIds.value.some(c => c.id === 1)) {
         selectedCraftIds.value.push(1)
      }
   })

   const parameters = ref({
      page: 1,
      perPage: 10,
      order_direction: 'asc',
      group_by: [],
      order_by: 'name',
      selected_locals: '',
      search: '',
      watch: [cacheBuster.value]
   })
   const parametersHidden = ref({
      page: 1,
      perPage: 10,
      order_direction: 'asc',
      group_by: [],
      order_by: 'name',
      selected_locals: '',
      search: '',
      watch: [cacheBuster.value]
   })
   const paramsShowAll = computed(() => {
      return { ...parameters.value, showall: true }
   })
   const paramsShowAllHidden = computed(() => {
      return { ...parametersHidden.value, showall: true, status: 0 }
   })
   const paramsShowSubHidden = computed(() => {
      return { ...parametersHidden.value, status: 0 }
   })

   const itemId = ref<number>(0)
   const itemBody = ref({})

   function $reset(): void {
      activeCrafts.value = []
      activeTotal.value = 0
      hiddenCrafts.value = []
      hiddenTotal.value = 0
      selectedCraftIds.value = [1]
      isEditMode.value = false
      parameters.value.search = ''
      parameters.value.selected_locals = ''
      parametersHidden.value.search = ''
      parametersHidden.value.selected_locals = ''
      itemId.value = 0
      itemBody.value = {}
      isPending.value = false
   }

   const apiUrl = computed(() => `${config.public.API_URL}/api/crafts/${itemId.value === 0 ? '' : itemId.value}` )
   const apiUrl_post = computed(() => `${config.public.API_URL}/api/craft_post` )
   const apiSubscriptionUrl = computed(() => `${config.public.API_URL}/api/local/craft_list`)
   const apiSwapCraftsUrl = computed(() => `${config.public.API_URL}/api/local/swap_crafts`)
   const apiIncorporateUrl = computed(() => `${config.public.API_URL}/api/craft/take_ownership/${itemId.value}`)
   
   const { data: fetchSubActiveCraftsData, error: fetchSubActiveCraftsError, execute: execFetchSubActiveCrafts } = useFetch(() => { return apiUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: parameters,
      immediate: false,
      watch: false,
   })
   const { data: fetchAllActiveCraftsData, error: fetchAllActiveCraftsError, execute: execFetchAllActiveCrafts } = useFetch(() => { return apiUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsShowAll,
      immediate: false,
      watch: false,
   })
   async function fetchActiveCrafts(showAll: boolean = false, getAll: boolean = false): Promise<void> {
      console.log('craftStore.fetchActiveCrafts()')
      if (isPending.value) return

      isPending.value = true
      cacheBuster.value += 1

      const currPerPage = parameters.value.perPage
      const currPerPageShowAll = paramsShowAll.value.perPage
      if (getAll) {
         parameters.value.perPage = Number.MAX_SAFE_INTEGER
         paramsShowAll.value.perPage = Number.MAX_SAFE_INTEGER
      }
      
      itemId.value = 0
 
      if (showAll) {
         await execFetchAllActiveCrafts()
 
         if (fetchAllActiveCraftsError.value) {
             console.log('craftStore.fetchActiveCrafts().fetchAllActiveCraftsError:', fetchAllActiveCraftsError.value as ErrorT)
             showError(fetchAllActiveCraftsError.value as ErrorT)
         }
         else if (fetchAllActiveCraftsData.value) {
             console.log('craftStore.fetchActiveCrafts().fetchAllActiveCraftsData:', fetchAllActiveCraftsData.value)
             activeCrafts.value = fetchAllActiveCraftsData.value.data as Array<T>
             activeTotal.value = fetchAllActiveCraftsData.value.total
         }
      }
      else {
         await execFetchSubActiveCrafts()
 
         if (fetchSubActiveCraftsError.value) {
             console.log('craftStore.fetchActiveCrafts().fetchSubActiveCraftsError:', fetchSubActiveCraftsError.value as ErrorT)
             showError(fetchSubActiveCraftsError.value as ErrorT)
         }
         else if (fetchSubActiveCraftsData.value) {
             console.log('craftStore.fetchActiveCrafts().fetchSubActiveCraftsData:', fetchSubActiveCraftsData.value)
             selectedCraftIds.value = (fetchSubActiveCraftsData.value.data as Array<T>).map(s => s.id)

             activeCrafts.value = fetchSubActiveCraftsData.value.data as Array<T>
             activeTotal.value = fetchSubActiveCraftsData.value.total
         }
      }

      parameters.value.perPage = currPerPage
      paramsShowAll.value.perPage = currPerPageShowAll
      isPending.value = false
   }

   const { data: fetchSubHiddenData, error: fetchSubHiddenError, execute: execFetchSubHidden } = useFetch(() => { return apiUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsShowSubHidden,
      immediate: false,
      watch: false,
   })
   const { data: fetchAllHiddenData, error: fetchAllHiddenError, execute: execFetchAllHidden } = useFetch(() => { return apiUrl.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsShowAllHidden,
      immediate: false,
      watch: false,
   })
   async function fetchHiddenCrafts(showAll: boolean = false): Promise<void> {
      console.log('craftStore.fetchHiddenCrafts()')
      console.log('paramsShowAllHidden:', paramsShowAllHidden)
      if (isPending.value) return

      isPending.value = true
      cacheBuster.value += 1

      if (showAll) {
         await execFetchAllHidden()

         if (fetchAllHiddenError.value) {
            console.log('craftStore.fetchHiddenCrafts().fetchAllHiddenError:', fetchAllHiddenError.value as ErrorT)
            showError(fetchAllHiddenError.value as ErrorT)
         } else if (fetchAllHiddenData.value) {
            console.log('craftStore.fetchHiddenCrafts().fetchAllHiddenData:', fetchAllHiddenData.value)
            hiddenCrafts.value = fetchAllHiddenData.value.data as Array<T>
            hiddenTotal.value = fetchAllHiddenData.value.total
         }
      }
      else {
         await execFetchSubHidden()

         if (fetchSubHiddenError.value) {
            console.log('taskStore.fetchHiddenCrafts().fetchSubHiddenError:', fetchSubHiddenError.value as ErrorT)
            showError(fetchSubHiddenError.value as ErrorT)
         }
         else if (fetchSubHiddenData.value) {
            console.log('craftStore.fetchHiddenCrafts().fetchSubHiddenData:', fetchSubHiddenData.value)
            hiddenCrafts.value = fetchSubHiddenData.value.data as Array<T>
            hiddenTotal.value = fetchSubHiddenData.value.total
         }
      }

      isPending.value = false
   }

   const swapBody = ref({
      old_id: 0,
      new_id: 0
   })
   const { data: swapCraftsData, error: swapCraftsError, execute: execSwapCrafts } = useFetch (() => { return apiSwapCraftsUrl.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: swapBody,
      immediate: false,
      watch: false,
   })
   async function swapCrafts(old_id: number, new_id: number): Promise<void> {
      console.log('craftStore.swapCrafts()')
      if (isPending.value) return

      isPending.value = true
      cacheBuster.value += 1

      swapBody.value = {
         old_id: old_id,
         new_id: new_id
      }

      await execSwapCrafts()

      if (swapCraftsError.value) {
         console.log('craftStore.setSubscriptions().swapCraftsError:', swapCraftsError.value as ErrorT)
         setSnackbar({
            type: 'error',
            text: `Could not update Craft List.`
         })
         showError(swapCraftsError.value as ErrorT)
      }
      else if (swapCraftsData.value) {
         console.log('craftStore.setSubscriptions().swapCraftsData:', swapCraftsData.value)

         const found = selectedCraftIds.value.find(id => id === old_id)
         if (found) {
            const index = selectedCraftIds.value.indexOf(found)
            selectedCraftIds.value[index] = new_id
         }
      }

      isPending.value = false
   }

   const { data: setSubscriptionsData, error: setSubscriptionsError, execute: execSetSubscriptions } = useFetch(() => { return apiSubscriptionUrl.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: itemBody,
      immediate: false,
      watch: false,
   })
   async function setSubscriptions(): Promise<void> {
      console.log('craftStore.setSubscriptions()')

      isPending.value = true
      cacheBuster.value += 1

      itemBody.value = {
         craft_list: selectedCraftIds.value,
         local_id: useUserStore().current.local.id
      }

      await execSetSubscriptions()

      if (setSubscriptionsError.value) {
         console.log('craftStore.setSubscriptions().setSubscriptionsError:', setSubscriptionsError.value as ErrorT)
         setSnackbar({
            type: 'error',
            text: `Could not update Craft List.`
         })
         showError(setSubscriptionsError.value as ErrorT)
      }
      else if (setSubscriptionsData.value) {
         console.log('craftStore.setSubscriptions().setSubscriptionsData:', setSubscriptionsData.value)
         setSnackbar({
            type: 'success',
            text: `Craft List successfully updated!`
         })
      }

      isPending.value = false
   }

   const { data: createData, error: createError, execute: execCreate} = useFetch(() => { return apiUrl_post.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: itemBody,
      immediate: false,
      watch: false,
   })
   async function createCraft(name: string, local_id: number = 0): Promise<T> {
      console.log('craftStore.createCraft()')
      if (isPending.value) return {} as T

      isPending.value = true
      cacheBuster.value += 1

      let newCraft = {} as T
      itemId.value = 0
      if (local_id > 0) {
         itemBody.value = { name: name, local_id: local_id }
      }
      else {
         itemBody.value = { name: name }
      }

      await execCreate()

      if (createError.value) {
      console.log('craftStore.createCraft().createError:', createError.value as ErrorT)
      if (createError.value.data.message === 'Duplicate Name')
         setSnackbar({
            type: `error`,
            text: `"${name}" already exists. Craft may be hidden.`,
         })
      else
         showError(createError.value as ErrorT)
      }
      else if (createData.value){
         console.log('craftStore.createCraft().createData:', createData.value)
         setSnackbar({
            type: `success`,
            text: `"${name}" successfully created!`,
         })
         newCraft = createData.value as T
      }
      
      isPending.value = false
      return newCraft
   }

   const { data: deleteData, error: deleteError, execute: execDelete } = useFetch(() => { return apiUrl.value }, {
      method: 'DELETE',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })
   async function hideCraft(craft: T): Promise<boolean> {
      console.log('craftStore.hideCraft()')
      if (isPending.value) return false

      isPending.value = true
      cacheBuster.value += 1

      let success = false
      itemId.value = craft.id

      await execDelete()

      if (deleteError.value) {
         console.log('craftStore.hideCraft().deleteError:', deleteError.value as ErrorT)
         showError(deleteError.value as ErrorT)
      }
      else if (deleteData.value) {
         console.log('craftStore.hideCraft().deleteData:', deleteData.value)
         success = true
      }
      
      isPending.value = false
      return success
   }

   const restoreObj = ref({})
   const restoreBody = computed(() => restoreObj.value)
   const restoreURL = computed(() => `${config.public.API_URL}/api/crafts/bulk_restore`)
   const { data: restoreData, error: restoreError, execute: execRestore } = useFetch(() => { return restoreURL.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: restoreBody,
      immediate: false,
      watch: false,
   })
   async function restoreCrafts(ids: Array<number>): Promise<void> {
      console.log('craftStore.restoreCrafts()', ids)
      if (isPending.value) return

      isPending.value = true
      restoreObj.value = {
         craft_list: ids,
      }

      await execRestore()

      if (restoreError.value) {
         console.log('craftStore.restoreCrafts().restoreError:', restoreError.value as ErrorT)
         showError(restoreError.value as ErrorT)
      } else if (restoreData.value) {
         console.log('craftStore.restoreCrafts().restoreData:', restoreData.value)
         setSnackbar({
            type: `success`,
            text: 'Craft(s) successfully restored.',
         })
      }

      isPending.value = false
   }

   return {
      parameters,
      parametersHidden,
      isPending,
      activeCrafts,
      activeTotal,
      hiddenCrafts,
      hiddenTotal,
      selectedCraftIds,
      isEditMode,
      fetchActiveCrafts,
      fetchHiddenCrafts,
      createCraft,
      hideCraft,
      restoreCrafts,
      setSubscriptions,
      swapCrafts,
      $reset
   }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useCraftStore, import.meta.hot))
}