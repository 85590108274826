import type { Snackbar as T } from '~/types'

export const useSnackbarStore = defineStore('snackbarStore', () => {
   const snackbar = ref<T>({} as T)
   const isShown = ref<boolean>(false)

   function setSnackbar(sb: T): void {
      console.log('setSnackbar()', sb)

      isShown.value = true
      snackbar.value.type = sb.type || 'success'
      snackbar.value.text = sb.text || ''
      snackbar.value.timeout = sb.timeout || 2500

      switch (snackbar.value.type) {
         case 'success':
            snackbar.value.icon = 'mdi-check-circle'
            snackbar.value.color = 'success'
            break
         case 'info':
            snackbar.value.icon = 'mdi-information'
            snackbar.value.color = 'info'
            break
         case 'warning':
            snackbar.value.icon = 'mdi-alert-circle'
            snackbar.value.color = 'warning'
            break
         case 'error':
            snackbar.value.icon = 'mdi-close-circle'
            snackbar.value.color = 'error'
            break
         default:
            snackbar.value.icon = 'mdi-check-circle'
            snackbar.value.color = 'success'
            break
      }
   }

   return {
      isShown, snackbar, setSnackbar
   }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useSnackbarStore, import.meta.hot));
}
